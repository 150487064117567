import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ContextPovider } from './UserContext/UserContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
const clientId = '714559225747-m64jip29kas86h466nfnu9l0db7ticvl.apps.googleusercontent.com'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <ContextPovider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ContextPovider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);


