/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { feathersClient } from '../feathersClient/feathersClient';
import axios from 'axios';
import { format, isSameDay, isToday, isYesterday, subDays } from 'date-fns';
import { toast } from 'react-toastify';

const UserContext = createContext();


export const ContextPovider = ({ children }) => {

  const [isVisitor, SetIsVisitor] = useState(false);
  const [isUsers, SetIsUsers] = useState(false);
  const [isAdmin, SetIsAdmin] = useState(false);
  const [isAdminSuper, SetIsAdminSuper] = useState(false);
  const [isSincrire, SetIsSincrire] = useState(false);

  const [compte, setCompte] = useState({});
  const userId = localStorage.getItem('userId');
  const Tokeni = localStorage.getItem('accessToken_Data');
  const messageService = feathersClient.service('message');
  const usersService = feathersClient.service('users');
  const [unreadCount, setUnreadCount] = useState(0);



  const tokenUtile = {
    headers: { Authorization: `Bearer ${Tokeni}` },
  };

  //......................users data.....................
  const dataUserConnected = async () => {
    await axios.get(`https://rtc.eac-advisor.org/users/${userId}`)
      .then((reponse) => {
        setCompte(reponse.data)
      })
      .catch((error) => {
        console.log("Il y a une erreur lors de la recuperation des information d'un utilisateur connectes", error)
      })
  }
  useEffect(() => {
    dataUserConnected()
  }, [userId])

  const [usersList, setUsersList] = useState([]);
  const [userSelected, setUserSelected] = useState(null);

  const feathersUser_with_Messages = async () => {
    // const user_user = await usersService.find();
    // setUsersList(user_user);

  };
  const setSelected = (user) => {
    // setUserSelected(user);
  };
  useEffect(() => {

    const handleUsersCreated = (newUsers) => {
      setUsersList((prevUsers) => {
        if (!Array.isArray(prevUsers)) {
          return [newUsers];
        }
        return [...prevUsers, newUsers];
      });
    };

    feathersClient.service('users').on('created', handleUsersCreated);

    feathersUser_with_Messages();

    return () => {
      feathersClient.service('users').off('created', handleUsersCreated);
    };
  }, []);





  const removeDuplicateUsers = (users, idToRemove) => {
    const uniqueUsers = new Map();
    users.forEach(user => {
      // Vérifier si l'utilisateur a un ID différent de celui à retirer
      if (user.id !== idToRemove) {
        // Utiliser l'ID de l'utilisateur comme clé pour garantir l'unicité
        if (!uniqueUsers.has(user.id)) {
          uniqueUsers.set(user.id, user);
        }
      }
    });

    // Convertir le Map en tableau et retourner
    return Array.from(uniqueUsers.values());
  };

  // ............................Envoyer un message..........................
  const [unsentMessages, setUnsentMessages] = useState({});
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [messages_All_unread, setMessages_All_unread] = useState([]);
  const [unreadCountMessages, setUnreadCountsMessages] = useState([]);

  const featchAllMessage = async () => {
    await feathersClient.authenticate({
      strategy: 'jwt',
      accessToken: Tokeni,
    });
    const response = await messageService.find()
    const { unreadCounts } = response;
    setMessages_All_unread(unreadCounts)
  }
  const fetchMessages = async () => {
    if (!userSelected || !userSelected.id) {
      console.error("Aucun utilisateur sélectionné pour récupérer les messages.");
      setMessages([]);
      return;
    }

    try {

      if (!Tokeni) {
        console.error("Le jeton d'authentification est manquant. Veuillez vous connecter.");
        return;
      }

      await feathersClient.authenticate({
        strategy: 'jwt',
        accessToken: Tokeni,
      });

      const response = await messageService.find({
        query: {
          $or: [
            { sender_Id: parseInt(userId), receiver_Id: userSelected.id },
            { sender_Id: userSelected.id, receiver_Id: parseInt(userId) },
          ],
        },
      });

      const { messages } = response;
      setMessages(messages || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des messages :", error);
    }
  };


  useEffect(() => {
    fetchMessages();
    featchAllMessage()
  }, []);


  const handleSubmitMessage = async (e) => {
    e.preventDefault();
    if (message.trim() === '') {
      toast.warning("Le champ de message ne doit pas être vide.");
      return;
    }

    if (!userSelected || !userSelected.id) {
      toast.warning("Aucun utilisateur sélectionné.");
      return;
    }

    const newMessage = {
      id: Date.now(),
      content: message,
      user_id: parseInt(userId),
      isRead: userSelected.id === parseInt(userId),
      createdAt: new Date(),
      status: 'pending',
    };

    setMessages((prev) => [...prev, newMessage]);



    try {
      if (!Tokeni) {
        toast.warning("Le jeton d'authentification est manquant. Veuillez vous connecter pour continuer.");
        return;
      }
      setMessage('');
      await feathersClient.authenticate({
        strategy: 'jwt',
        accessToken: Tokeni,
      });

      const createdMessage = await messageService.create({
        content: message,
        receiver_Id: userSelected.id,
      });

      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === newMessage.id ? { ...createdMessage, status: 'success' } : msg
        )
      );

      fetchMessages();
      setUnsentMessages((prev) => {
        const newState = { ...prev };
        delete newState[userSelected.id];
        return newState;
      });
    } catch (error) {
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === newMessage.id ? { ...msg, status: 'error' } : msg
        )
      );
      console.error("Erreur lors de l'envoi du message :", error.message);
    }
  };



  useEffect(() => {
    if (userSelected && userSelected.id) {
      setMessage(unsentMessages[userSelected.id] || '');
    }
  }, [userSelected]);

  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    setUnsentMessages((prev) => {
      const updatedMessages = { ...prev, [userSelected.id]: newMessage };
      return updatedMessages;
    });
  };


  useEffect(() => {
    const handleMessageCreated = (newMessage) => {
      setMessages((prevMessages) => {
        if (!Array.isArray(prevMessages)) {
          return [newMessage];
        }
        return [...prevMessages, newMessage];
      });
      if (parseInt(newMessage.receiver_Id) === parseInt(userId) && !newMessage.isRead) {
        setUnreadCount(prevCount => prevCount + 1);
      }
      fetchMessages();
      feathersUser_with_Messages()
    };

    messageService.on('created', handleMessageCreated);
    fetchMessages();

    return () => {
      messageService.off('created', handleMessageCreated);
    };
  }, [Tokeni, parseInt(userId), userSelected]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Regroupe les messages par date
  const groupMessagesByDate = (messages) => {
    const groupedMessages = {};
    messages.forEach((msg) => {
      const msgDate = new Date(msg.createdAt);
      let groupKey = format(msgDate, 'dd-MM-yyyy');
      if (isToday(msgDate)) {
        groupKey = 'Aujourd\'hui';
      } else if (isYesterday(msgDate)) {
        groupKey = 'Hier';
      } else if (isSameDay(subDays(new Date(), 2), msgDate)) {
        groupKey = 'Avant-hier';
      }
      if (!groupedMessages[groupKey]) {
        groupedMessages[groupKey] = [];
      }
      groupedMessages[groupKey].push(msg);
    });
    return groupedMessages;
  };



  const renderMessages = () => {
    if (!Array.isArray(messages)) {
      return null;
    }
    const groupedMessages = groupMessagesByDate(messages);
    return Object.keys(groupedMessages).map((dateKey) => (
      <div key={dateKey}>

        <div className="text-center mt-10 relative my-2">
          <span className="bg-[#262ea3] px-2 py-1 text-white uppercase rounded">{dateKey}</span>
        </div>
        {groupedMessages[dateKey].map((msg, index, arr) => {
          const previousMessage = arr[index - 1];
          const isSameSender = previousMessage && previousMessage.user_id === msg.user_id;

          // console.log("isSameSender:", isSameSender)
          // console.log("msg:", msg)

          return (
            <div key={msg.id} className={`mt-1 w-full relative mb-2 ${parseInt(msg.sender_Id) === parseInt(userId) ? 'text-end flex justify-end' : 'flex justify-start'} rounded`}>
              <div className={`py-1 px-2 p-4 relative ${!isSameSender && parseInt(msg.sender_Id) !== parseInt(userId) ? 'rounded-e-xl rounded-b-xl' : ' rounded-xl'} ${parseInt(msg.sender_Id) === parseInt(userId) ? 'bg-[#25cc25] text-white text-end rounded-s-xl rounded-b-xl mr-1 max-w-[70%]' : 'bg-gray-100 text-start'} ${!isSameSender && parseInt(msg.sender_Id) !== parseInt(userId) ? null : 'ml-0'} max-w-[70%] max-sm:w-[95%]`}>
                {!isSameSender && parseInt(msg.sender_Id) !== parseInt(userId) && (
                  <small className='text-red-600 text-[11px]'>{msg.sender_Id}</small>
                )}

                {!isSameSender && parseInt(msg.sender_Id) === parseInt(msg.receiver_Id) && (
                  <small className='text-red-600 text-[11px]'>Vous meme</small>
                )}
                <p className='text-[18px] whitespace-break-spaces'>{msg.content}</p>
                <div className={`flex mt-1 p-1 text-[10px]   ${parseInt(msg.sender_Id) === parseInt(userId) ? 'text-white justify-end' : 'text-gray-400 justify-start'}`}>
                  {format(new Date(msg.createdAt), 'HH:mm:ss')}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ));
  };
  //  ...............message non lue avec utilisateurs

  const usersWithMessages = usersList && usersList.map((user) => {
    // const sentMessage = messages_All_unread.find((msg) => parseInt(msg.sender_Id) === user.id);
    // //  const receivedMessage = messages_All_unread.find((msg) => msg.receiver_Id === user.id);
    // return {
    //   ...user,
    //   sent_Message_Count_unRead: sentMessage ? sentMessage.count : 0,
    // };
  });

  // ......................reste..................
  const [infoUsers, setInfoUsers] = useState(false)
  const [idUsers, GetIdUsers] = useState(null)
  const [nodifi_en_Contact, Getnodifi_en_Contact] = useState({})


  useEffect(() => {
    async function fetchUser() {
      try {
        const authData = await feathersClient.get('authentication');
        if (authData) {
          GetIdUsers(authData.user.id)

        } else {
          console.log('Utilisateur non connecté');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'utilisateur connecté :', error);
      }
    }

    fetchUser();
  }, []);


  useEffect(() => {
    const notifier_en_Contact = (data) => {
      console.log('----etre_en_contact---', data)
      Getnodifi_en_Contact(data)
    }
    usersService.on('etre_en_contact', notifier_en_Contact);
    return () => {
      usersService.off('etre_en_contact', notifier_en_Contact);
    };
  }, []);


  return (
    <UserContext.Provider value={{
      nodifi_en_Contact, Getnodifi_en_Contact,
      infoUsers, setInfoUsers,
      idUsers, GetIdUsers,

      // ...........................messages.................
      renderMessages,
      handleMessageChange,
      groupMessagesByDate,
      handleSubmitMessage,
      messages,
      setMessages,
      fetchMessages,
      unreadCountMessages, setUnreadCountsMessages,
      unsentMessages, setUnsentMessages,
      messagesEndRef,
      usersWithMessages,
      // ..........................users...................
      feathersUser_with_Messages,
      compte,
      userSelected,
      // setSelected,
      setUnreadCount,
      unreadCount,
      messageService,
      Tokeni,
      userId,
      tokenUtile,
      isAdmin, SetIsAdmin,
      isAdminSuper, SetIsAdminSuper,
      isVisitor, SetIsVisitor,
      isSincrire, SetIsSincrire,
      isUsers, SetIsUsers
    }}>
      {children}
    </UserContext.Provider>
  );
};


export const useThemes = () => {
  return useContext(UserContext);
};










