/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import './App.css'
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "apexcharts/dist/apexcharts.css";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'rsuite/dist/rsuite.min.css';
import 'aos/dist/aos.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "leaflet-geosearch/dist/geosearch.css";
import "leaflet/dist/leaflet.css";
import "react-chat-elements/dist/main.css"


import { Routes, Route, useNavigate, } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { CustomProvider } from "rsuite";
import Registrant from "./Component/formilaire/Registrant";
import Login from "./Component/formilaire/Login";
import RegistrantAdmin from "./Component/formilaire/RegistrantAdmin";
import { useEffect } from 'react';
import { useThemes } from './UserContext/UserContext';
import Chat_general from './Component/Chat_general';


function App() {

    const {
        isSincrire, isAdmin, SetIsAdmin, isUsers, SetIsUsers,
        isVisitor, SetIsVisitor, Tokeni } = useThemes()
    const role = localStorage.getItem('status');
    const userid = localStorage.getItem('userId');;
    const navig = useNavigate()

    useEffect(() => {
        if (Tokeni && role === "admin" && userid) {
            SetIsAdmin(true);
            SetIsUsers(false)
            SetIsVisitor(false)
           
            navig("/chat/admin")
        } else if (Tokeni && role === 'users' && userid) {
            SetIsAdmin(false);
            navig("/chat/users")
            SetIsUsers(true)
            SetIsVisitor(false)
          
        } else {
            SetIsVisitor(true)
            navig("/")
        }
    }, [role, isAdmin, isUsers, isVisitor, Tokeni, userid]);


    return isVisitor ? (
        <>
            {!isSincrire && <Login />}
            {isSincrire && <Registrant />}
            <ToastContainer />
        </>

    ) : isUsers ? (
        <CustomProvider>
            <Routes>
                <Route path="/chat/users" Component={Chat_general} />
            </Routes>
            <ToastContainer />
        </CustomProvider>

    ) : isAdmin ? (
        <CustomProvider>
            <Routes>
                <Route path="/chat/admin" Component={Chat_general} />
            </Routes>
            <ToastContainer />
        </CustomProvider>
    ) : null

}

export default App
