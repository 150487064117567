import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { feathersClient } from '../../feathersClient/feathersClient';
import { useThemes } from '../../UserContext/UserContext';

function CompteModifie({ compte, setModifierCompte }) {


    const [idUsers, GetIdUsers] = useState(null)
    const [profil, setProfil] = useState(null);
    const [profilnew, GetprofilNew] = useState()


    const [nom_complet, setName] = useState('');
    const elemenRefname = useRef(null);

    const [tel, settel] = useState('');
    const elemenReftel = useRef(null);

    const [email, setEmail] = useState('');
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const elemenRefemail = useRef(null);

    const [password, setPassword] = useState('');
    const PasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const elemenRefpassword = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (nom_complet.trim() === '') {
            toast.warning('Le nom est obligatoire !!');
            elemenRefname.current && elemenRefname.current.focus();
            return false;
        }
        if (tel.trim() === '') {
            toast.warning('Le numéro de téléphone est obligatoire !!');
            elemenReftel.current && elemenReftel.current.focus();
            return false;
        }
        if (email.trim() === '' || !email.match(emailRegex)) {
            toast.error("L'email est incorrect !!");
            elemenRefemail.current && elemenRefemail.current.focus();
            return false;
        }

        const formData = new FormData();
        if (profilnew) {
            formData.append("profil", profilnew)
        }

        formData.append('nom_complet', nom_complet);
        formData.append('userId', idUsers);
        formData.append('email', email);
        formData.append('phone', tel);
        formData.append('status', 'users');
        formData.append('IsActive', true);


        try {
            await axios.put('https://rtc.eac-advisor.org/users/modifCompte', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            toast.success("Vous inscrire avec success")
            setProfil(null)
            setName('')
            settel('')
            setEmail('')
            setPassword('')
            setModifierCompte(false)
        } catch (error) {
            if (error.response.data.message === 'Email_existe') {
                toast.warning("Cet email est déjà existe  dans notre système");
                elemenRefemail.current && elemenRefemail.current.focus();
            }
            console.error('Erreur:', error);
        }
    }


    useEffect(() => {
        setProfil(compte.profil)
        setName(compte.nom_complet)
        setEmail(compte.email)
        settel(compte.phone)
        setEmail(compte.email)
    }, [compte])


    useEffect(() => {
        async function fetchUser() {
            try {
                const authData = await feathersClient.get('authentication');
                if (authData) {
                    GetIdUsers(authData.user.id)

                } else {
                    console.log('Utilisateur non connecté');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'utilisateur connecté :', error);
            }
        }

        fetchUser();
    }, []);
    return (
        <>
            <form onSubmit={handleSubmit} className="bg-white my-4 p-6 rounded shadow-md w-full max-sm:h-full sm:w-96">
                <div className="w-full  relative mb-2">
                    <label htmlFor="photo"
                        className={`w-full  border border-gray-300 text-black  block cursor-pointer  outline-none focus:border-orange-500  rounded-md p-2.5  bg-transparent  `}
                    >Photo de profil
                    </label>
                    <input
                        type="file"
                        name="photo"
                        accept="image/*"
                        id="photo"
                        onChange={(e) => { GetprofilNew(e.target.files[0]); setProfil('') }}
                        hidden />
                </div>
                {profil ? <div className='w-[15em] mt-[1em]  relative  rounded-lg h-[15em] border border-gray-300'>
                    <img src={`https://rtc.eac-advisor.org/imageUsers/${profil}`} className='w-full h-full object-cover' alt='      ' />
                </div>
                    : profilnew ?
                        <div className='w-[15em] mt-[1em]  relative  rounded-lg h-[15em] border border-gray-300'>
                            <img src={URL.createObjectURL(profilnew)} className='w-full h-full object-cover' alt='      ' />
                        </div> : null
                }

                <div className="mb-4">
                    <label className="block text-gray-700" htmlFor="name">Nom complet</label>
                    <input
                        type="text"
                        id="name"
                        ref={elemenRefname}
                        value={nom_complet}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full p-2 outline-none focus:border-blue-700 border border-gray-300 rounded" />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700" htmlFor="tel">Numéro de téléphone</label>
                    <input
                        type="text"
                        id="tel"
                        ref={elemenReftel}
                        value={tel}
                        onChange={(e) => settel(e.target.value)}
                        className="w-full p-2 outline-none focus:border-blue-700 border border-gray-300 rounded" />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700" htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        ref={elemenRefemail}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 border outline-none focus:border-blue-700 border-gray-300 rounded" />
                </div>

                <div className="mb-4 hidden">
                    <label className="block text-gray-700" htmlFor="password">Mot de Passe</label>
                    <input
                        type="password"
                        id="password"
                        ref={elemenRefpassword}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 border outline-none focus:border-blue-700 border-gray-300 rounded" />
                </div>


                <button type="submit" className="bg-blue-500 text-white w-full p-2 rounded hover:bg-blue-600">
                    Modifier
                </button>
            </form>
        </>
    )
}

export default CompteModifie