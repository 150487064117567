import React, { useEffect, useState } from 'react'
import { Drawer } from 'rsuite'
import CompteModifie from './CompteModifie'

function Compte({ hundleDeconnexion, compte }) {
    const [modifierCompte, setModifierCompte] = useState(false)



    return (
        compte ? (
            <div className="max-w-lg mx-auto my-0 bg-white rounded-lg overflow-hidden">
                <div className="flex items-center p-3">
                    <div className="w-16 h-16">
                        {compte.profil !== null ? (
                            <img
                                src={`https://rtc.eac-advisor.org/imageUsers/${compte.profil}`}
                                className=" w-full h-full object-cover rounded-full"
                                alt='     '
                            />
                        ) : (
                            <div className='bg-green-600 w-full h-full flex items-center text-3xl justify-center text-white text-[14px] font-bold rounded-full'>
                                {compte.nom_complet && compte.nom_complet.charAt(0)}
                            </div>
                        )}

                    </div>
                    <div className="ml-4">
                        <h1 className="text-2xl font-bold text-gray-800">{compte && compte.nom_complet}</h1>
                        <p className="text-gray-600">{compte.email}</p>
                        <p className="mt-2 hidden">
                            <span className="inline-block w-2 h-2  rounded-full bg-green-500"></span>
                            <span className="text-gray-600">En ligne</span>
                        </p>
                    </div>
                </div>

                <div className="border-t border-gray-300">
                    <div className="px-6">
                        <h2 className="text-lg font-semibold text-gray-800">Informations du compte</h2>
                        <div className="mt-4">
                            <p className="text-gray-700"><strong>Id : </strong>{compte.id}</p>
                            <p className="text-gray-700"><strong>Roles : </strong>{compte.status ? compte.status : 'non definie'}</p>
                            <p className="text-gray-700"><strong>Telephone : </strong>{compte.phone ? compte.phone : 'non definie'}</p>
                            <p className="text-gray-700"><strong>Date de création : </strong>{new Date(compte.createdAt).toLocaleString("fr-FR")}</p>
                            <p className="text-gray-700"><strong>Date de modification : </strong>{new Date(compte.updatedAt).toLocaleString("fr-FR")}</p>

                        </div>
                    </div>
                </div>

                <div className="p-6 flex justify-between items-center">
                    <div onClick={() => setModifierCompte(true)} className=" cursor-pointer inline-block bg-blue-500 text-white p-2  m-1 rounded hover:bg-blue-600 transition duration-300">Modifier</div>
                    <div onClick={() => hundleDeconnexion()} className=" cursor-pointer inline-block bg-green-500 text-white p-2  m-1 rounded hover:bg-[#25cc25] transition duration-300">Déconnecter</div>
                    <button onClick={() => hundleDeconnexion()} disabled className=" cursor-pointer opacity-15 inline-block bg-red-500 text-white p-2  m-1 rounded hover:bg-red-600 transition duration-300">Supprimer</button>
                </div>
                <Drawer placement='left' size="xs" open={modifierCompte} onClose={() => setModifierCompte(false)}>
                    <Drawer.Header>
                        <Drawer.Title className='font-bold text-center'>Modifier le compte</Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                        <CompteModifie compte={compte} setModifierCompte={setModifierCompte} />
                    </Drawer.Body>
                </Drawer>
            </div>
        ) : (
            <div className='w-full  h-full flex justify-center items-center text-gray-700 text-lg'>
                Recuperation des données d'un utilisateur
            </div>
        )

    )
}

export default Compte