/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
// import { ArrowLeft, Mail, MoreVertical, Phone, Plus, Search, Settings, Share2, SquareUserRound, UsersRound, Video } from 'lucide-react'
// import React from 'react'
// import { useThemes } from '../UserContext/UserContext';
// import { Popover, Whisper } from 'rsuite';
// import { useNavigate } from 'react-router-dom';

// function Chat_general() {
//     const {
//         setSelected, userSelected, SetIsVisitor, compte,
//         handleSubmitMessage, message, handleMessageChange, userId, renderMessages,
//         messagesEndRef, usersWithMessages } = useThemes();
//     const isOnline = true
//     const lastSeen = "2 min ago"

// const navig = useNavigate()
// const hundleDeconnexion = () => {
//     localStorage.removeItem('accessToken_Data')
//     localStorage.removeItem('userId')
//     navig("/")
//     SetIsVisitor(true)
// }
//     return (
//         <div className='w-full h-screen overflow-hidden flex'>
//             <div className='w-[20%]'>
//                 <div className="bg-white border-b flex justify-between items-center h-[10vh] w-full border-gray-200 p-1 sm:px-4 sm:py-3 shadow-sm">
//                     <div className='text-lg'><span className='font-serif'>BTR</span> Chat</div>
//                     <div>
//                         <button className="p-2 hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
//                             <Plus className="w-5 h-5 text-gray-600" />
//                         </button>
// <Whisper
//     trigger="click"
//     placement='bottom'
//     speaker={
//         <Popover>
//             <div>
//                 <div className='pb-2 w-full gap-2   flex justify-start items-center text-[15px]'>
//                     <SquareUserRound size={18} />  {compte.nom_complet}
//                 </div>
//                 <div className='pb-2 w-full  gap-2   flex justify-start items-center text-[15px]'>
//                     <Mail size={18} />   {compte.email}
//                 </div>
//                 <div className='pb-2 w-full gap-2 flex justify-start items-center text-[15px]'>
//                     <Phone size={18} />  {compte.phone}
//                 </div>
//                 <div onClick={hundleDeconnexion} className='p-0.5 m-0.5 w-full bg-red-400  text-[15px] hover:bg-red-600 text-white cursor-pointer flex justify-center items-center  font-bold'>
//                     Déconnection
//                 </div>

//             </div>
//         </Popover>
//     }
// >
//     <button className="p-2 hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
//         <Settings className="w-5 h-5 text-gray-600" />
//     </button>
// </Whisper>
//                     </div>
//                 </div>
//                 <div className="bg-white border-b flex justify-between items-center h-[3em] w-full border-gray-200 p-1 shadow-sm">
//                     <button className="p-2 hover:bg-gray-100 text-[#25cc25] font-extrabold rounded-full transition-colors" aria-label="Video Call">
//                         Chat
//                     </button>
//                     <button className="p-2 hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
//                         Groupe
//                     </button>
//                     <button className="p-2 hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
//                         Non lues
//                     </button>
//                     <button className="p-2 hover:bg-gray-100 rounded-full transition-colors" aria-label="Search">
//                         <Search className="w-5 h-5 text-gray-600" />
//                     </button>
//                 </div>

//                 <div className='overflow-y-auto overflow-x-hidden w-full h-[83vh]'>
//                     {usersWithMessages && usersWithMessages.map((user) => (
//                         <div key={user.id} onClick={() => setSelected(user)} className={`flex items-center w-full hover:scale-105  duration-500   cursor-pointer hover:bg-gray-100 transition-all ${userSelected !== null && userSelected.id === user.id ? 'border-x-2  border-[#25cc25]  bg-[#25cc2523] rounded-none' : 'border-gray-50 border-y-[0.1px]'} p-2 rounded relative`}>
//                             <img src={`https://rtc.eac-advisor.org/imageUsers/${user.profil}`} alt={`${user.nom_complet}`} className="w-10 h-10 rounded-full mr-1" />
//                             <div className='w-full'>
//                                 <div className="text-gray-700 text-[14px]">{user.email}</div>
//                                 <div className="text-gray-400 text-[12px] py-1 w-full text-nowrap text-ellipsis overflow-hidden">j'utilise BTR Chat</div>
//                                 <div className="text-gray-300 text-[11px]">{user && user.sentAt}</div>
//                                 <span className="absolute top-0.5 right-3 text-[blue]  text-[10px]">
//                                     En ligne
//                                 </span>
//                                 {user.sent_Message_Count_unRead > 0 && (
//                                     <span className="absolute top-5 right-3 bg-[#25cc25] text-white rounded-full px-2 py-1 text-xs">
//                                         {user.sent_Message_Count_unRead}
//                                     </span>
//                                 )}
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <div className='w-[80%]'>
//                 {userSelected ?
//                     <>
//                         <div className="bg-white border-b h-[10vh] w-full  border-gray-200 p-1 sm:px-4 sm:py-3 shadow-sm">
//                             <div className="max-w-7xl mx-auto flex items-center justify-between">
//                                 <div className="flex items-center sm:space-x-4">
//                                     <button
//                                         className="p-2 hover:bg-gray-100 hidden rounded-full transition-colors"
//                                         aria-label="Go Back"
//                                     >
//                                         <ArrowLeft className="w-5 h-5 text-gray-600" />
//                                     </button>

//                                     <div className="flex items-center space-x-3">
//                                         <div className="relative">
//                                             <img
//                                                 src={`https://rtc.eac-advisor.org/imageUsers/${userSelected.profil}`}
//                                                 alt={`${userSelected.nom_complet}'s avatar`}
//                                                 className="w-10 h-10 rounded-full object-cover"
//                                             />
// {isOnline && (
//     <span className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white" />
// )}
//                                         </div>

//                                         <div className="flex flex-col">
//                                             <div className="font-semibold max-sm:text-[12px] text-gray-800">{userSelected.nom_complet}</div>
//                                             <span className="text-sm text-gray-500">
//                                                 {isOnline ? 'Online' : `Last seen: ${lastSeen}`}
//                                             </span>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="flex items-center sm:space-x-2">
//                                     <button
//                                         className="p-2 hover:bg-gray-100 rounded-full  transition-colors"
//                                         aria-label="Search"
//                                     >
//                                         <Search className="w-5 h-5 text-gray-600" />
//                                     </button>

//                                     <button
//                                         className="p-2 hover:bg-gray-100 rounded-full transition-colors"
//                                         aria-label="Phone Call"
//                                     >
//                                         <Phone className="w-5 h-5 text-gray-600" />
//                                     </button>

//                                     <button
//                                         className="p-2 hover:bg-gray-100  rounded-full transition-colors"
//                                         aria-label="Video Call"
//                                     >
//                                         <Video className="w-5 h-5 text-gray-600" />
//                                     </button>



//                                     <div className="relative">
//                                         <button
//                                             className="p-2 hover:bg-gray-100 rounded-full transition-colors"
//                                             aria-label="Notifications"
//                                         >
//                                             <UsersRound className="w-5 h-5 text-gray-600" />
//                                         </button>
//                                         <span className="absolute -top-1 -right-1 bg-green-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
//                                             0
//                                         </span>

//                                     </div>


//                                     <button
//                                         className="p-2 hover:bg-gray-100 hidden rounded-full transition-colors"
//                                         aria-label="Share"
//                                     >
//                                         <Share2 className="w-5 h-5 text-gray-600" />
//                                     </button>

//                                     <button
//                                         className="p-2 hover:bg-gray-100 rounded-full hidden transition-colors"
//                                         aria-label="More Options"
//                                     >
//                                         <MoreVertical className="w-5 h-5 text-gray-600" />
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className='overflow-hidden'>
//                             <div className="p-2 h-[80vh] overflow-y-auto overflow-x-hidden">
//                                 {renderMessages()}
//                                 <div ref={messagesEndRef} />
//                             </div>
//                             <form onSubmit={handleSubmitMessage} className="p-4 h-[10vh] border-t">
//                                 <div className="flex items-center">
//                                     <textarea
//                                         value={message}
//                                         onChange={handleMessageChange}
//                                         placeholder={userSelected && userSelected.id === parseInt(userId) ? "Écrire un message à Vous" : `Écrire un message à ${userSelected?.nom_complet || 'utilisateur'}`}
//                                         className="flex-1 p-2 bg-transparent resize-none border-none outline-none"
//                                     />
//                                     <button type="submit" className="ml-2 bg-[#25cc25] text-white p-2 rounded-md">Envoyer</button>
//                                 </div>
//                             </form>
//                         </div>

//                     </>
//                     :
// <div className="flex items-center justify-center flex-col h-full text-gray-500">
//     <div className="text-[5em] text-gray-200 font-bold text-nowrap uppercase font-serif">
//         btr chat
//     </div>
//     <p>Sélectionnez un utilisateur pour voir les messages</p>
// </div>
//                 }


//             </div>
//         </div>
//     )
// }

// export default Chat_general





// import React, { useState, useEffect } from 'react';
// import { feathersClient } from '../feathersClient/feathersClient';

// const MessageForm = () => {
//     const [message, setMessage] = useState('');
//     const [messages, setMessages] = useState([]);
//     console.log("message:", message)
//     useEffect(() => {
//         // Récupérer les messages existants à l'initialisation
//         feathersClient.service('message').find().then((response) => {
//             setMessages(response.data);
//         });

//         // Écouter les nouveaux messages
//         feathersClient.service('message').on('created', (newMessage) => {
//             setMessages((currentMessages) => [...currentMessages, newMessage]);
//         });

//         // Nettoyer l'écouteur d'événements
//         return () => {
//             feathersClient.service('message').removeListener('created');
//         };
//     }, []);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (message.trim()) {
//             await feathersClient.service('message').create({ text: message });
//             setMessage('');
//         }
//     };
//     console.log("messages:", messages)

//     return (
//         <div>
//             <h2>Messages</h2>
//             <div>
//                 {/* {messages.map((msg) => (
//                     <div key={msg.id}>
//                         <p>{msg.text}</p>
//                     </div>
//                 ))} */}
//             </div>
//             <form onSubmit={handleSubmit}>
//                 <textarea
//                     value={message}
//                     onChange={(e) => setMessage(e.target.value)}
//                     placeholder="Écrivez votre message..."
//                 ></textarea>
//                 <button type="submit">Envoyer</button>
//             </form>
//         </div>
//     );
// };

// export default MessageForm;























































import React, { useEffect, useRef, useState } from 'react';
import { feathersClient, socket } from '../feathersClient/feathersClient';
import axios from 'axios';
import { toast } from 'react-toastify';
import { format, isSameDay, isToday, isYesterday, subDays, differenceInDays } from 'date-fns';
import { fr } from 'date-fns/locale';

import { Copy, Eye, ArrowRightCircle, Share2, ArrowLeft, CheckCheck, Clock7, Clock8, Dot, Edit, Mail, MessageCircleOff, MessageSquare, MoreVertical, Phone, PhoneForwarded, Pin, Plus, PlusCircle, Search, Settings, SquareUserRound, Trash2, User, UserPlus, UserRoundPlus, UsersRound, Video, X, ArrowRight, HeadphoneOff, Group, XCircle, UserRoundSearch } from 'lucide-react';
import { Drawer, Dropdown, Popover, Whisper, Menu, IconButton } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import Compte from './formilaire/Compte';



import { useThemes } from '../UserContext/UserContext';

import Create_Groupe from './Create_Groupe';

const Chat_general = () => {
    const { SetIsVisitor, infoUsers, setInfoUsers, tokenUtile } = useThemes()
    const navig = useNavigate()
    const [showMenu, setShowMenu] = useState(false);
    const [showLecteur, setShowLecteur] = useState(false)
    const [mobile, setMobile] = useState(window.innerWidth < 700);
    const [isSlideMobile, setIsSlideMobile] = useState(true);

    const [isTyping, setIsTyping] = useState(false);;
    const typingTimeout = 3000;
    let typingTimer = null;


    useEffect(() => {
        const handleSize = () => {
            setMobile(window.innerWidth < 700);
        };

        window.addEventListener('resize', handleSize);
        return () => {
            window.removeEventListener('resize', handleSize);
        };
    }, []);



    const [compte, setCompte] = useState({});
    const [create_groupe, setcreate_groupe] = useState(false);
    const [opensAjouter, setOpensAjouter] = useState(false);
    const Tokeni = localStorage.getItem('accessToken_Data');


    const usersService = feathersClient.service('users');
    const messageService = feathersClient.service('message');
    const userId_login = localStorage.getItem('userId');
    const messagesEndRef = useRef(null);
    const messages_groupeEndRef = useRef(null);


    const [usersList_online_getted, setUsersList_online_getted] = useState([]);

    const [usersAllList, setUsersAllList] = useState([])
    const [usersList, setUsersList] = useState([])
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedGroup_Id, setSelectedGroup_Id] = useState(null);
    const [selected_groupe, setSelected_Groupe] = useState(false);

    const [selectedUser_Online, setSelectedUser_Online] = useState(false);
    const [selectedUser_Chat, setSelectedUser_Chat] = useState(true);
    const [selectedUser_Group, setSelectedUser_Group] = useState(false);
    const [selectedUser_Non_lue, setSelectedUser_Non_lue] = useState(false);

    const [messages, setMessages] = useState([]);
    const [messages_non_lue, setMessages_non_lue] = useState([]);
    const [newMessages, setNewMessages] = useState({});
    const selectedGroup_Ids = localStorage.getItem("selectedGroup_Id")
    const [typingUsers, setTypingUsers] = useState([]);
    const [typingUsers_group, setTypingUsers_groupe] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTerm_Group, setSearchTerm_Group] = useState('');
    const [unreadUsersCount, setUnreadUsersCount] = useState(0);
    const [unreadUsersCount_groupe, setUnreadUsersCount_groupe] = useState(0);









    const [popoverOpenUserId, setPopoverOpenUserId] = useState(null);

    const handlePopoverOpen = (userId) => {
        setPopoverOpenUserId(userId);
    };
    const handlePopoverClose = () => {
        setPopoverOpenUserId(null);
    };
    useEffect(() => {
        const handleVisibilityChange = async () => {
            if (document.hidden) {
                socket.emit('visibility_change', { isPageVisible: 'oui' });
                await fetchUsersWithMessages();
                await fetchUsersWithMessages_nonLue();

                await fethersGrouper_Users();
            } else {
                await fetchUsersWithMessages();
                await fetchUsersWithMessages_nonLue();

                await fethersGrouper_Users();
                socket.emit('visibility_change', { isPageVisible: 'non' });

            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        socket.on('changeStateOnline_offline', () => {
            fetchUsersWithMessages()
            fetchUsersWithMessages_nonLue()

            fethersGrouper_Users()
        });

        return () => {
            socket.off('changeStateOnline_offline');
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [socket]);

    //......................users data.....................
    const dataUserConnected = async () => {
        await axios.get(`https://rtc.eac-advisor.org/users/${parseInt(userId_login, 10)}`)
            .then((reponse) => {
                setCompte(reponse.data)
            })
            .catch((error) => {
                console.log("Il y a une erreur lors de la recuperation des information d'un utilisateur connectes", error)
            })
    }
    useEffect(() => {
        dataUserConnected()
    }, [userId_login])
    useEffect(() => {
        const updateCompte = (data) => {
            dataUserConnected();
        };
        socket.on('modifier_compte', updateCompte);
        return () => {
            socket.off('modifier_compte', updateCompte);
        };
    }, [socket]);
    const fetchMessages = async (selectedUserId) => {
        if (!selectedUserId) {
            console.error("Aucun utilisateur sélectionné pour récupérer les messages.");
            return;
        }

        try {
            if (!Tokeni) {
                console.error("Le jeton d'authentification est manquant. Veuillez vous connecter.");
                return;
            }

            await feathersClient.authenticate({
                strategy: 'jwt',
                accessToken: Tokeni,
            });

            const response = await messageService.find({
                query: {
                    $or: [
                        { sender_Id: parseInt(userId_login, 10), receiver_Id: parseInt(selectedUserId, 10) },
                        { sender_Id: parseInt(selectedUserId, 10), receiver_Id: parseInt(userId_login, 10) },
                    ],
                    $sort: { createdAt: 1 },
                },
            });

            if (Array.isArray(response)) {
                setMessages(prev => ({
                    ...prev,
                    [selectedUserId]: response,
                }));
            } else {
                console.error("La réponse n'est pas valide :", response);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des messages :", error);
        }
    };
    const handleSendMessage = async (userId) => {
        const messageContent = newMessages[userId]?.trim();
        if (!messageContent) {
            toast.warning("Le message ne peut pas être vide.");
            return false;
        }

        // Identifiant unique pour le message temporaire
        const tempId = Date.now();

        // Ajouter le message temporaire à l'état local
        setMessages((prevMessages) => ({
            ...prevMessages,
            [userId]: [...(prevMessages[userId] || []), {
                id: tempId,
                content: messageContent,
                sender_Id: parseInt(userId_login, 10),
                receiver_Id: parseInt(userId, 10),
                isTemporary: true,
                createdAt: new Date(),
            }],
        }));

        // Réinitialiser immédiatement le champ de saisie
        setNewMessages((prevNewMessages) => ({
            ...prevNewMessages,
            [userId]: '',
        }));
        localStorage.setItem('newMessages', JSON.stringify({ ...newMessages, [userId]: '' }));

        try {
            const serverMessage = await messageService.create({
                content: messageContent,
                receiver_Id: parseInt(userId, 10),
            });

            // Remplacer le message temporaire par la réponse du serveur
            setMessages((prevMessages) => ({
                ...prevMessages,
                [userId]: (prevMessages[userId] || []).map((msg) =>
                    msg.id === tempId ? serverMessage : msg
                ),
            }));
        } catch (error) {
            console.error("Erreur lors de l'envoi du message :", error);

            // Supprimer le message temporaire en cas d'erreur
            setMessages((prevMessages) => ({
                ...prevMessages,
                [userId]: (prevMessages[userId] || []).filter(
                    (msg) => msg.id !== tempId
                ),
            }));

            // Ajouter une notification pour informer l'utilisateur
            toast.error("Erreur lors de l'envoi du message.");
        }
    };
    useEffect(() => {

        const handleUsersCreated = (newUsers) => {
            setUsersAllList((prevUsers) => {
                if (!Array.isArray(prevUsers)) {
                    return [newUsers];
                }
                return [...prevUsers, newUsers];
            });
            fetchUsersWithMessages()

        };
        const feathersUser_with_Messages = async () => {
            const user_user = await usersService.find();
            setUsersAllList(user_user);

        };
        feathersUser_with_Messages()
        feathersClient.service('users').on('created', handleUsersCreated);
        return () => {
            feathersClient.service('users').off('created', handleUsersCreated);
        };
    }, []);
    useEffect(() => {
        const handleMessageCreated = async (message) => {

            if (message.receiver_Id) {
                setMessages(prevMessages => {
                    const existingMessages = prevMessages[message.receiver_Id] || [];

                    // Vérifiez si le message existe déjà
                    if (existingMessages.some(m => m.id === message.id)) {
                        return prevMessages; // Ne rien changer si le message existe déjà
                    }

                    // Mettre à jour les messages


                    // Mettre à jour les messages
                    const updatedMessages = {
                        ...prevMessages,
                        [message.receiver_Id]: [...existingMessages, message],
                        [message.sender_Id]: [
                            ...(prevMessages[message.sender_Id] || []),
                            message,
                        ],
                    };

                    // Mettez à jour le statut de lecture pour le dernier message
                    if (message.receiver_Id === parseInt(userId_login, 10)) {
                        updatedMessages[message.sender_Id] = updatedMessages[message.sender_Id].map(msg => {
                            if (msg.id === message.id) {
                                return { ...msg, isRead: "oui" };
                            }
                            return msg;
                        });
                    }

                    return updatedMessages;
                });

                // Mettre à jour le compteur de messages non lus uniquement si l'utilisateur n'est pas sélectionné
                if (selectedUser !== message.receiver_Id && selectedUser !== message.sender_Id) {
                    setUsersList(prevUsers =>
                        prevUsers.map(user =>
                            user.id === message.sender_Id
                                ? { ...user, unreadMessagesSent: (user.unreadMessagesSent || 0) + 1 }
                                : user
                        )
                    );
                }
                if (selectedUser === message.sender_Id && parseInt(userId_login, 10) === message.receiver_Id) {
                    try {
                        await axios.patch(`https://rtc.eac-advisor.org/users/mess_All/${message.sender_Id}/read`);
                        setMessages((prevMessages) => {
                            const updatedMessages = { ...prevMessages };
                            updatedMessages[message.receiver_Id] = updatedMessages[message.receiver_Id]?.map((msg) => {
                                if (msg.isRead === "non") {
                                    return { ...msg, isRead: "oui" };
                                }
                                return msg;
                            });
                            return updatedMessages;
                        });
                        fetchUsersWithMessages();
                        fetchUsersWithMessages_nonLue()
                        fethersGrouper_Users()
                    } catch (error) {
                        console.error("Erreur lors de la mise à jour des messages comme lus :", error);
                    }
                }

                const seleGroup = parseInt(selectedGroup_Ids, 10)
                fetchGroupMessages(seleGroup)

                fetchUsersWithMessages();
                fethersGrouper_Users()

                fetchUsersWithMessages_nonLue()
            } else {
                const seleGroup = parseInt(selectedGroup_Ids, 10)

                fetchGroupMessages(seleGroup)
                fetchUsersWithMessages();
                fethersGrouper_Users()
                fetchUsersWithMessages_nonLue()
            }

        };

        messageService.on('created', handleMessageCreated);
        return () => {
            messageService.off('created', handleMessageCreated);
        };
    }, [selectedUser, selectedGroup_Ids]);
    useEffect(() => {
        const handleMessageRead = (data) => {
            if (parseInt(data.receiver_Id, 10) === parseInt(userId_login, 10)) {
                setMessages(prevMessages => {
                    const updatedMessages = { ...prevMessages };
                    const messageKeys = Object.keys(updatedMessages);

                    messageKeys.forEach(key => {
                        updatedMessages[key] = updatedMessages[key].map(message => {
                            if (message.isRead === "non") {
                                return { ...message, isRead: "oui" };
                            }
                            return message;
                        });
                    });

                    return updatedMessages;
                });
            }
            const seleGroup = parseInt(selectedGroup_Ids, 10)
            fetchGroupMessages(seleGroup)
            fetchUsersWithMessages()
            fetchUsersWithMessages_nonLue()
            fethersGrouper_Users()
        };


        usersService.on('messages_isRead', handleMessageRead);
        return () => {
            usersService.off('messages_isRead', handleMessageRead)
        };
    }, [selectedUser, selectedGroup_Ids]);
    const [delayedMessages_users, setDelayedMessages_users] = useState({});
    useEffect(() => {
        const timeoutIds = [];
        Object.keys(messages).forEach((usersSelected) => {
            timeoutIds.push(
                setTimeout(() => {
                    setDelayedMessages_users((prev) => ({
                        ...prev,
                        [usersSelected]: messages[usersSelected],
                    }));
                }, 100)
            );
        });

        return () => timeoutIds.forEach((id) => clearTimeout(id));
    }, [messages]);
    const renderMessages = () => {
        const messages = delayedMessages_users[selectedUser] || [];

        if (!messages || messages.length === 0) {
            return (
                <div className="text-gray-500 h-full flex flex-col justify-center items-center">
                    <div>Aucun message trouvé pour</div>
                    <div className='font-bold ml-2'>{parseInt(userId_login) === selectedUser ? 'Vous' : usersList.find(user => user.id === selectedUser)?.name}</div>
                </div>
            );
        }

        const groupedMessages = groupMessagesByDate(messages);

        // Créer un Set pour suivre les ids des messages déjà affichés
        const displayedMessageIds = new Set();



        return Object.keys(groupedMessages).map(dateKey => (
            <div key={dateKey} className="mb-4">
                <div className="font-semibold w-full my-3 flex justify-center items-center">
                    <div className="text-center text-gray-500 text-sm mb-4">{dateKey}</div>
                </div>

                {groupedMessages[dateKey].map((sms, i, arr) => {
                    if (displayedMessageIds.has(sms.id)) {
                        return null;
                    }
                    displayedMessageIds.add(sms.id);
                    const previousMessage = arr[i - 1];
                    const isSameSender = previousMessage && previousMessage.sender_Id === sms.sender_Id;
                    return (
                        <div key={sms.id || i} className={`flex w-full ${sms.sender_Id === parseInt(userId_login, 10) ? 'justify-end' : 'justify-start'}`}>

                            <div className={`p-2 mb-2 w-max relative max-w-[80%] rounded ${sms.sender_Id === parseInt(userId_login, 10) ? 'bg-[#25cc25] text-white text-end' : 'bg-gray-200 text-start text-gray-800'}`}>
                                {!isSameSender && parseInt(sms.sender_Id, 10) !== parseInt(userId_login, 10) &&
                                    <div className={`w-full h-2 rounded-bl-full  bg-gray-50 left-0 absolute`}></div>
                                }
                                {!isSameSender && parseInt(sms.receiver_Id, 10) !== parseInt(userId_login, 10) &&
                                    <div className={`w-full h-2 rounded-ee-full bg-gray-50 right-0 absolute`}></div>
                                }
                                {!isSameSender && parseInt(sms.receiver_Id) === parseInt(userId_login, 10) && parseInt(sms.sender_Id) === parseInt(userId_login, 10) && (
                                    <small className='text-red-600 text-[10px]'>Vous</small>
                                )}

                                <div className='whitespace-break-spaces font-sans'>{sms.content}</div>
                                <div className={`flex mt-1 w-full items-center  text-[10px] ${parseInt(sms.sender_Id) === parseInt(userId_login) ? 'text-white justify-end' : 'text-gray-400 justify-start'}`}>


                                    <div>{format(new Date(sms.createdAt), 'HH:mm:ss')}</div>
                                    {sms.sender_Id === parseInt(userId_login, 10) && (
                                        <span className="text-xs text-gray-400 ml-2">
                                            {sms.isRead === "oui" ?
                                                <CheckCheck size={15} color='white' /> :
                                                sms.isTemporary ?
                                                    <Clock8 size={15} color='gray' /> :
                                                    <CheckCheck size={15} color='gray' />}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })
                }
            </div >
        ));
    };
    const handleInputChange = (userId, value) => {
        const updatedMessages = {
            ...newMessages,
            [userId]: value,
        };
        setNewMessages(updatedMessages);
        localStorage.setItem('newMessages', JSON.stringify(updatedMessages));
        if (!isTyping) {
            setIsTyping(true);
            socket.emit('typing', { isTyping: true, targetUserId: selectedUser });
        }

        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            setIsTyping(false);
            socket.emit('typing', { isTyping: false, targetUserId: selectedUser });
        }, typingTimeout);

    };
    const groupMessagesByDate = (messagesArray) => {
        const groupedMessages = {};

        messagesArray.forEach(message => {
            const date = new Date(message.createdAt);
            if (isNaN(date.getTime())) {
                console.error("Date invalide pour le message:", message);
                return;
            }

            let dateKey;
            if (isToday(date)) {
                dateKey = "Aujourd'hui";
            } else if (isYesterday(date)) {
                dateKey = "Hier";
            } else if (isSameDay(subDays(new Date(), 2), date)) {
                dateKey = "Avant-hier";
            } else {
                dateKey = format(date, 'dd MMMM yyyy'); // Format de date personnalisé
            }

            if (!groupedMessages[dateKey]) {
                groupedMessages[dateKey] = [];
            }

            groupedMessages[dateKey].push(message);
        });

        return groupedMessages;
    };
    useEffect(() => {
        if (selectedUser) {
            fetchMessages(selectedUser);
        }
        const seleGroup = parseInt(selectedGroup_Ids, 10)
        fetchGroupMessages(seleGroup)

        fetchUsersWithMessages()
        fethersGrouper_Users()
        fetchUsersWithMessages_nonLue()

    }, [selectedUser, selectedGroup_Ids]);
    useEffect(() => {
        const savedUser = JSON.parse(localStorage.getItem('selectedUser'));
        const savedMessages = JSON.parse(localStorage.getItem('messages')) || {};
        setMessages(savedMessages);
        if (savedUser) {
            setSelectedUser(savedUser);
            fetchMessages(savedUser);
        }
        const seleGroup = parseInt(selectedGroup_Ids, 10)
        fetchGroupMessages(seleGroup)
        fetchUsersWithMessages();
        fethersGrouper_Users()
            ;
        fetchUsersWithMessages_nonLue()
    }, [selectedUser, selectedGroup_Ids]);
    const handleUserClick = (userId) => {
        if (userId) {
            setSelectedUser(userId)
            localStorage.setItem('selectedUser', JSON.stringify(userId));
            fetchMessages(userId);
            markAllMessagesAsRead(userId);
        }
    };
    const markAllMessagesAsRead = async (userId) => {
        const currentUserId = parseInt(userId_login, 10);
        try {
            if (userId === currentUserId) {
                console.log(`Aucun besoin de mise à jour : ${userId} correspond à l'utilisateur connecté avec id ${currentUserId}.`);
                return;
            }
            const response = await axios.patch(`https://rtc.eac-advisor.org/users/mess_All/${userId}/read`);
            console.log(`${response.data.message} pour l'utilisateur ${userId}`);
            setMessages(prevMessages => {
                const updatedMessages = { ...prevMessages };
                const messageKeys = Object.keys(updatedMessages);
                messageKeys.forEach(key => {
                    updatedMessages[key] = updatedMessages[key].map(message => {
                        if (message.isRead === "non") {
                            return { ...message, isRead: "oui" };
                        }
                        return message;
                    });
                });
                return updatedMessages;
            });

            fetchUsersWithMessages();
            fethersGrouper_Users()
            fetchUsersWithMessages_nonLue()
        } catch (error) {
            console.error('Erreur lors de la mise à jour des messages :', error);
        }
    };
    const fetchUsersWithMessages = async () => {
        const params = {
            query: {
                query: searchTerm
            }
        };

        try {
            const userResponse = await usersService.find(params);
            const users = userResponse.data || userResponse;

            // Initialisez un compteur d'utilisateurs avec messages non lus
            let unreadUsersCounts = 0;

            const usersWithMessages = await Promise.all(users.map(async user => {
                const lastMessageResponse = await messageService.find({
                    query: {
                        $or: [
                            { sender_Id: parseInt(userId_login, 10), receiver_Id: user.id },
                            { sender_Id: user.id, receiver_Id: parseInt(userId_login, 10) },
                        ],
                        $sort: { createdAt: -1 },
                        $limit: 1,
                    },
                });

                const lastMessage = Array.isArray(lastMessageResponse) && lastMessageResponse.length > 0 ? lastMessageResponse[0] : null;

                const isCurrentUser = user.id === parseInt(userId_login, 10);
                const sentUnreadMessages = user.sentUnreadMessagesByReceiver.find(msg => msg.receiver_Id === parseInt(userId_login, 10));
                const receivedUnreadMessages = user.receivedUnreadMessagesBySender.find(msg => msg.sender_Id === parseInt(userId_login, 10));

                const sentUnreadCount = sentUnreadMessages ? sentUnreadMessages.count : 0;
                const receivedUnreadCount = receivedUnreadMessages ? receivedUnreadMessages.count : 0;

                const unreadMessages_all = isCurrentUser ? 0 : sentUnreadCount;


                if (unreadMessages_all > 0) {
                    unreadUsersCounts += 1;
                }

                const is_Sender_id = (lastMessage && lastMessage.sender_Id === parseInt(userId_login, 10));

                const lastSeenDate = user.lastSeen ? new Date(user.lastSeen) : null;

                let formattedLastSeen;
                if (lastSeenDate) {
                    if (isToday(lastSeenDate)) {
                        formattedLastSeen = `Aujourd'hui à ${format(lastSeenDate, 'HH:mm', { locale: fr })}`;
                    } else if (isYesterday(lastSeenDate)) {
                        formattedLastSeen = `Hier à ${format(lastSeenDate, 'HH:mm', { locale: fr })}`;
                    } else if (differenceInDays(new Date(), lastSeenDate) < 7) {
                        formattedLastSeen = `${format(lastSeenDate, 'EEEE à HH:mm', { locale: fr })}`;
                    } else {
                        formattedLastSeen = format(lastSeenDate, 'dd/MM/yyyy à HH:mm', { locale: fr });
                    }
                } else {
                    formattedLastSeen = 'Hors ligne';
                }

                return {
                    id: user.id,
                    name: user.nom_complet,
                    photo: user.profil,
                    unreadMessages_all,
                    unreadMessagesSent: sentUnreadCount,
                    unreadMessagesReceived: receivedUnreadCount,
                    email: user.email,
                    IsActive: user.IsActive,
                    status: user.status,
                    etat: user.etat === 'online' ? 'En ligne' : formattedLastSeen,
                    isSendId: is_Sender_id,
                    lastMessage: lastMessage
                        ? (lastMessage.sender_Id === parseInt(userId_login, 10) ? `Vous: ${lastMessage.content}` : lastMessage.content)
                        : "j'utilise le chat brt",
                    lastMessageTime: lastMessage ? new Date(lastMessage.createdAt) : null,
                };
            }));

            const filteredUsersOnline = usersWithMessages.filter(user => user.etat === 'En ligne' && user.id !== parseInt(userId_login, 10));

            const sortedUsers = usersWithMessages.sort((a, b) => {
                if (a.lastMessageTime && b.lastMessageTime) {
                    return b.lastMessageTime - a.lastMessageTime;
                }
                return a.lastMessageTime ? -1 : 1;
            });

            const sortedUsersUsersOnline = filteredUsersOnline.sort((a, b) => {
                if (a.lastMessageTime && b.lastMessageTime) {
                    return b.lastMessageTime - a.lastMessageTime;
                }
                return a.lastMessageTime ? -1 : 1;
            });

            // Mettez à jour les états avec les données triées et le compteur des utilisateurs avec messages non lus
            setUsersList_online_getted(sortedUsersUsersOnline);
            setUsersList(sortedUsers);

            // Mettez le compteur des utilisateurs ayant des messages non lus
            setUnreadUsersCount(unreadUsersCounts);

        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs :', error);
        }
    };
    useEffect(() => {
        fetchUsersWithMessages()
    }, [searchTerm])
    const render_utilisateur = () => {
        return (
            <div >
                <div className={`p-2 flex  items-center`}>
                    <input
                        type="text"
                        className="w-full px-2 py-1.5 border outline-none focus:border-[#25cc25] rounded-md"
                        placeholder="Rechercher par nom ou email"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}

                    />
                </div>
                {
                    usersList && usersList.length > 0 ? usersList.map((user) => (
                        <div
                            key={user.id}
                            onClick={(e) => { handleUserClick(user.id); setIsSlideMobile(false); e.stopPropagation() }}
                            className={`flex items-center w-full hover:scale-105  duration-500   cursor-pointer  transition-all ${selectedUser === user.id ? 'border-x-2  border-[#25cc25]  bg-[#25cc2533] rounded-none' : 'border-gray-50 border-y-[0.1px] hover:bg-gray-100 bg-white'} p-2 rounded relative`}
                        >


                            <div className="flex items-center justify-start h-full w-full">
                                <div className='w-10 h-10 relative'>
                                    {user.photo !== null ? (
                                        <img
                                            src={`https://rtc.eac-advisor.org/imageUsers/${user.photo}`}
                                            className=" w-full h-full object-cover rounded-full mr-4"
                                            alt='     '
                                        />
                                    ) : (
                                        <div className='bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full'>
                                            {user.name && user.name.charAt(0)}
                                        </div>
                                    )}

                                    {user.etat === "En ligne" && (
                                        <span className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-white" />
                                    )}
                                </div>
                                <div className="w-full relative overflow-hidden h-12 ml-1">
                                    <div className="w-[70%] text-nowrap overflow-hidden text-xs text-ellipsis text-gray-500">
                                        {user.id === parseInt(userId_login, 10) ? <>Vous</> : <>~{user.name}~</>}
                                    </div>

                                    <div className="w-full absolute top-0 items-end flex flex-col pr-2">
                                        {user.lastMessageTime && (
                                            <div className="text-[10px] bg-[gray] rounded text-white p-1 first-letter:uppercase">
                                                {timeSince(user.lastMessageTime)}
                                            </div>
                                        )}
                                        {user.unreadMessagesSent > 0 && (
                                            <div className="bg-[#25cc25] w-5 h-5 right-1 flex justify-center items-center text-white text-[10px] rounded-full p-1">
                                                {user.unreadMessagesSent}
                                            </div>
                                        )}
                                    </div>

                                    <div className="text-gray-500 flex flex-col justify-center">
                                        {(newMessages[user.id] && selectedUser !== user.id) ? (
                                            <div className="flex">
                                                <div className="text-[red]">Brouillon:</div>
                                                <div className="text-nowrap overflow-hidden ml-1 text-ellipsis w-[60%]">
                                                    {newMessages[user.id]}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='flex items-center'>
                                                <div className={`${selectedUser === user.id ? 'text-gray-500' : 'text-gray-400'} text-[11px] text-ellipsis w-[80%] overflow-hidden text-nowrap`}>
                                                    {user.lastMessage}

                                                </div>
                                                {user.lastMessage !== "j'utilise le chat brt" && user.isSendId ? (
                                                    <div className="ml-2 relative top-1">
                                                        {user.unreadMessagesReceived === 0 ? (
                                                            <CheckCheck size={13} color="#25cc25" />
                                                        ) : (
                                                            <CheckCheck size={13} color="gray" />
                                                        )}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                        <div className="">

                                            {typingUsers.includes(user.id) ? (
                                                <div className="flex items-center">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-2 w-2 text-green-500"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <circle cx="10" cy="10" r="5" />
                                                    </svg>
                                                    <span className="ml-1 text-[blue] text-[9px]">En train de taper...</span>
                                                </div>


                                            ) : user.etat === "En ligne" ? (
                                                <div className="flex items-center">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-2 w-2 text-green-500"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <circle cx="10" cy="10" r="5" />
                                                    </svg>
                                                    <span className="ml-1 text-green-500 text-[9px]">En ligne</span>
                                                </div>
                                            ) : (
                                                <div className="flex items-center">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-2 w-2 text-gray-400"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <circle cx="10" cy="10" r="5" />
                                                    </svg>
                                                    <span className="ml-1 text-gray-400 text-[9px]">
                                                        <div className="text-[10px] text-gray-500">
                                                            {user.etat}
                                                        </div>
                                                    </span>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>


                                <Whisper
                                    placement="bottom"
                                    trigger="click"
                                    open={popoverOpenUserId === user.id}
                                    onClose={handlePopoverClose}
                                    speaker={
                                        <Popover>
                                            <div className="flex flex-col gap-2">
                                                <button
                                                    className="flex items-center gap-2 text-gray-700 hover:text-green-600"
                                                    onClick={() => {
                                                        handlePopoverClose();
                                                    }}
                                                >
                                                    <PlusCircle size={16} /> Ajouter
                                                </button>

                                                <button
                                                    className="flex items-center gap-2 text-gray-700 hover:text-red-600"
                                                    onClick={() => {
                                                        handlePopoverClose();
                                                    }}
                                                >
                                                    <MessageSquare size={16} /> Supprimer discussion
                                                </button>
                                                <button
                                                    className="flex items-center gap-2 text-gray-700 hover:text-red-600"
                                                    onClick={() => {
                                                        handlePopoverClose();
                                                    }}
                                                >
                                                    <Trash2 size={16} /> Supprimer
                                                </button>
                                                <div
                                                    onClick={(e) => {
                                                        localStorage.removeItem('selectedUser');
                                                        setSelectedUser(null);
                                                        handlePopoverClose();
                                                        e.stopPropagation();
                                                    }}
                                                    className="flex items-center gap-2 text-gray-700 cursor-pointer hover:text-gray-500"
                                                >
                                                    <X size={16} /> Fermer discussion
                                                </div>
                                            </div>
                                        </Popover>
                                    }
                                >
                                    <div
                                        className="p-2 hover:bg-gray-200 hidden rounded-full cursor-pointer transition-colors"
                                        onClick={(e) => {
                                            handlePopoverOpen(user.id);
                                            e.stopPropagation();
                                        }}
                                    >
                                        <MoreVertical className="w-4 h-4 text-gray-600" />
                                    </div>
                                </Whisper>
                            </div>


                        </div>
                    )) : (
                        <div className='w-full pt-10'>
                            <div className="text-gray-500 flex flex-col items-center p-4">
                                <UserRoundSearch size={100} />
                            </div>
                            <div className="text-gray-500  text-center p-2  cursor-pointer border-2">
                                Aucun utilisateur trouvé avec <span className='font-bold'>{searchTerm}</span>
                            </div>
                        </div>
                    )
                }
            </div>
        )


    };
    const fetchUsersWithMessages_nonLue = async () => {
        try {
            const reponse = await axios.get('https://rtc.eac-advisor.org/users/user_unRead');
            const usersList_online = reponse.data.users_Message_non_lue;

            if (!Array.isArray(usersList_online)) {
                console.warn('La liste des utilisateurs en ligne est invalide.');
                setMessages_non_lue([]);
                return;
            }

            const usersWithMessages = await Promise.all(
                usersList_online.map(async (user) => {
                    const lastMessageResponse = await messageService.find({
                        query: {
                            $or: [
                                { sender_Id: parseInt(userId_login, 10), receiver_Id: user.id },
                                { sender_Id: user.id, receiver_Id: parseInt(userId_login, 10) },
                            ],
                            $sort: { createdAt: -1 },
                            $limit: 1,
                        },
                    });

                    const lastMessage = Array.isArray(lastMessageResponse.data) && lastMessageResponse.data.length > 0
                        ? lastMessageResponse.data[0]
                        : null;

                    const isCurrentUser = user.id === parseInt(userId_login, 10);
                    const sentUnreadMessages = user.sentUnreadMessagesByReceiver.find(
                        (msg) => msg.receiver_Id === parseInt(userId_login, 10)
                    );
                    const receivedUnreadMessages = user.receivedUnreadMessagesBySender.find(
                        (msg) => msg.sender_Id === parseInt(userId_login, 10)
                    );

                    const sentUnreadCount = sentUnreadMessages ? sentUnreadMessages.count : 0;
                    const receivedUnreadCount = receivedUnreadMessages ? receivedUnreadMessages.count : 0;
                    const is_Sender_id = lastMessage && lastMessage.sender_Id === parseInt(userId_login, 10);

                    return {
                        id: user.id,
                        name: user.nom_complet,
                        photo: user.profil,
                        unreadMessages_all: isCurrentUser ? 0 : sentUnreadCount + receivedUnreadCount,
                        unreadMessagesSent: sentUnreadCount,
                        unreadMessagesReceived: receivedUnreadCount,
                        email: user.email,
                        IsActive: user.IsActive,
                        status: user.status,
                        etat: user.etat === 'online' ? 'En ligne' : 'Hors ligne',
                        isSendId: is_Sender_id,
                        lastMessage: lastMessage
                            ? (lastMessage.sender_Id === parseInt(userId_login, 10)
                                ? `Vous: ${lastMessage.content}`
                                : lastMessage.content)
                            : "j'utilise le chat brt",
                        lastMessageTime: lastMessage ? new Date(lastMessage.createdAt) : null,
                    };
                })
            );

            // Filtrer les utilisateurs avec unreadMessagesSent > 0
            const filteredUsers = usersWithMessages.filter((user) => user.unreadMessagesSent > 0);

            // Trier les utilisateurs en fonction de lastMessageTime
            const sortedUsers = filteredUsers.sort((a, b) => {
                if (a.lastMessageTime && b.lastMessageTime) {
                    return b.lastMessageTime - a.lastMessageTime; // Tri décroissant
                }
                return a.lastMessageTime ? -1 : 1;
            });

            setMessages_non_lue(sortedUsers);
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs :', error);
        }
    };
    const render_utilisateur_message_non_lue = () => {
        if (messages_non_lue.length === 0) {
            return (
                <div className='w-full pt-10'>
                    <div className="text-gray-500 flex flex-col items-center p-4">
                        <div> Aucun message non lu.</div>
                        <MessageCircleOff size={100} />
                    </div>
                    <div onClick={() => { setSelectedUser_Online(false); setSelectedUser_Chat(true); setSelectedUser_Group(false); setSelectedUser_Non_lue(false) }} className="text-blue-500 text-center p-2  cursor-pointer border-2">
                        Aller au discussion
                    </div>
                </div>
            );
        }
        return messages_non_lue.map((user) => (
            <div
                key={user.id}
                onClick={() => { handleUserClick(user.id); setIsSlideMobile(false) }}
                className={`flex items-center w-full hover:scale-105  duration-500   cursor-pointer  transition-all ${selectedUser === user.id ? 'border-x-2  border-[#25cc25]  bg-[#25cc2533] rounded-none' : 'border-gray-50 border-y-[0.1px] hover:bg-gray-100 bg-white'} p-2 rounded relative`}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{
                    duration: 0.5,
                    delay: 0.2,
                    ease: [0, 0.71, 0.2, 1.01],
                }}
            >


                <div className="flex items-center justify-start h-full w-full">
                    <div className='w-10 h-10 relative'>

                        {user.photo !== null ? (
                            <img
                                src={`https://rtc.eac-advisor.org/imageUsers/${user.photo}`}
                                className=" w-full h-full object-cover rounded-full mr-4"
                                alt='     '
                            />
                        ) : (
                            <div className='bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full'>
                                {user.name && user.name.charAt(0)}
                            </div>
                        )}
                        {user.etat === "En ligne" && (
                            <span className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-white" />
                        )}
                    </div>
                    <div className="w-full relative overflow-hidden h-12 ml-1">
                        <div className="w-[70%] text-nowrap overflow-hidden text-ellipsis">
                            {user.name}
                        </div>
                        <div className="w-full absolute top-0 items-end flex flex-col pr-2">
                            {user.lastMessageTime && (
                                <div className="text-[10px] bg-[gray] rounded text-white p-1 first-letter:uppercase">
                                    {timeSince(user.lastMessageTime)}
                                </div>
                            )}
                            {user.unreadMessagesSent > 0 && (
                                <div className="bg-[#25cc25] w-5 h-5 right-1 flex justify-center items-center text-white text-[10px] rounded-full p-1">
                                    {user.unreadMessagesSent}
                                </div>
                            )}
                        </div>
                        <div className="text-gray-500 flex flex-col justify-center">
                            {(newMessages[user.id] && selectedUser !== user.id) ? (
                                <div className="flex">
                                    <div className="text-[red]">Brouillon:</div>
                                    <div className="text-nowrap overflow-hidden ml-1 text-ellipsis w-[60%]">
                                        {newMessages[user.id]}
                                    </div>
                                </div>
                            ) : (
                                <div className='flex items-center'>
                                    <div className={`${selectedUser === user.id ? 'text-gray-500' : 'text-gray-400'} text-[11px] text-ellipsis w-[80%] overflow-hidden text-nowrap`}>
                                        {user.lastMessage}

                                    </div>
                                    {user.lastMessage !== "j'utilise le chat brt" && user.isSendId ? (
                                        <div className="ml-2 relative top-1">
                                            {user.unreadMessagesReceived === 0 ? (
                                                <CheckCheck size={13} color="#25cc25" />
                                            ) : (
                                                <Clock8 size={13} color="gray" />
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                            <div className="">
                                {user.etat === "En ligne" ? (
                                    <div className="flex items-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-2 w-2 text-green-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <circle cx="10" cy="10" r="5" />
                                        </svg>
                                        <span className="ml-1 text-green-500 text-[9px]">En ligne</span>
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-2 w-2 text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <circle cx="10" cy="10" r="5" />
                                        </svg>
                                        <span className="ml-1 text-gray-400 text-[9px]">Hors ligne</span>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        ));
    };
    const render_utilisateur_online = () => {

        if (usersList_online_getted.length === 0) {
            return (
                <div className='w-full pt-10'>
                    <div className="text-gray-500 flex flex-col items-center p-4">
                        <div> Aucun utilisateur en ligne.</div>
                        <HeadphoneOff size={100} />
                    </div>
                    <div onClick={() => { setSelectedUser_Online(false); setSelectedUser_Chat(true); setSelectedUser_Group(false); setSelectedUser_Non_lue(false) }} className="text-blue-500 text-center p-2  cursor-pointer border-2">
                        Aller au discussion
                    </div>
                </div>
            );
        }



        return usersList_online_getted.map((user) => (
            <div
                key={user.id}
                onClick={() => { handleUserClick(user.id); setIsSlideMobile(false) }}
                className={`flex items-center w-full hover:scale-105  duration-500   cursor-pointer  transition-all ${selectedUser === user.id ? 'border-x-2  border-[#25cc25]  bg-[#25cc2533] rounded-none' : 'border-gray-50 border-y-[0.1px] hover:bg-gray-100 bg-white'} p-2 rounded relative`}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{
                    duration: 0.5,
                    delay: 0.2,
                    ease: [0, 0.71, 0.2, 1.01],
                }}
            >


                <div className="flex items-center justify-start h-full w-full">
                    <div className='w-10 h-10 relative'>
                        {user.photo !== null ? (
                            <img
                                src={`https://rtc.eac-advisor.org/imageUsers/${user.photo}`}
                                className=" w-full h-full object-cover rounded-full mr-4"
                                alt='     '
                            />
                        ) : (
                            <div className='bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full'>
                                {user.name && user.name.charAt(0)}
                            </div>
                        )}
                        {user.etat === "En ligne" && (
                            <span className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-white" />
                        )}
                    </div>
                    <div className="w-full relative overflow-hidden h-12 ml-1">
                        <div className="w-[70%] text-nowrap overflow-hidden text-xs text-ellipsis text-gray-500">
                            {user.id === parseInt(userId_login, 10) ? <>Vous</> : <>~{user.name}~</>}
                        </div>
                        <div className="w-full absolute top-0 items-end flex flex-col pr-2">
                            {user.lastMessageTime && (
                                <div className="text-[10px] bg-[gray] rounded text-white p-1 first-letter:uppercase">
                                    {timeSince(user.lastMessageTime)}
                                </div>
                            )}
                            {user.unreadMessagesSent > 0 && (
                                <div className="bg-[#25cc25] w-5 h-5 right-1 flex justify-center items-center text-white text-[10px] rounded-full p-1">
                                    {user.unreadMessagesSent}
                                </div>
                            )}
                        </div>
                        <div className="text-gray-500 flex flex-col justify-center">
                            {(newMessages[user.id] && selectedUser !== user.id) ? (
                                <div className="flex">
                                    <div className="text-[red]">Brouillon:</div>
                                    <div className="text-nowrap overflow-hidden ml-1 text-ellipsis w-[60%]">
                                        {newMessages[user.id]}
                                    </div>
                                </div>
                            ) : (
                                <div className='flex items-center'>
                                    <div className={`${selectedUser === user.id ? 'text-gray-500' : 'text-gray-400'} text-[11px] text-ellipsis w-[80%] overflow-hidden text-nowrap`}>
                                        {user.lastMessage}

                                    </div>
                                    {user.lastMessage !== "j'utilise le chat brt" && user.isSendId ? (
                                        <div className="ml-2 relative top-1">
                                            {user.unreadMessagesReceived === 0 ? (
                                                <CheckCheck size={13} color="#25cc25" />
                                            ) : (
                                                <Clock8 size={13} color="gray" />
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                            <div className="">
                                {user.etat === "En ligne" ? (
                                    <div className="flex items-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-2 w-2 text-green-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <circle cx="10" cy="10" r="5" />
                                        </svg>
                                        <span className="ml-1 text-green-500 text-[9px]">En ligne</span>
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-2 w-2 text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <circle cx="10" cy="10" r="5" />
                                        </svg>
                                        <span className="ml-1 text-gray-400 text-[9px]">Hors ligne</span>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        ));
    };

    const timeSince = (date) => {
        const seconds = Math.floor((new Date() - new Date(date)) / 1000);

        let interval = Math.floor(seconds / 31536000);
        if (interval > 1) return `il y a ${interval} ans`;

        interval = Math.floor(seconds / 2592000);
        if (interval > 1) return `il y a ${interval} mois`;

        interval = Math.floor(seconds / 86400);
        if (interval > 1) return `il y a ${interval} j`;

        interval = Math.floor(seconds / 3600);
        if (interval > 0) {
            const minutes = Math.floor((seconds % 3600) / 60);
            return `il y a ${interval}h${minutes > 0 ? ':' + minutes : ''}`;
        }

        interval = Math.floor(seconds / 60);
        if (interval > 1) return `il y a ${interval} min`;

        return seconds > 1 ? `il y a ${seconds} s` : `à l'instant`;
    };
    const [allUsers_Online, SetallUsers_Online] = useState(0)
    useEffect(() => {
        SetallUsers_Online(usersList_online_getted.length)
    }, [usersList_online_getted.length])

    const render_header_Slide = () => {
        return (
            <>
                <div className="bg-white border-b flex justify-between items-center h-[7.5vh] w-full border-gray-200 p-1 sm:px-4 sm:py-3 shadow-sm">
                    <div className='text-lg'>
                        <span className='font-serif'>BTR</span> Chat</div>
                    <div>

                        <Whisper
                            trigger="hover"
                            placement='bottom'
                            speaker={
                                <Popover className='text-nowrap'>
                                    {parseInt(usersAllList.length).toLocaleString("FR-fr")} utilisateurs
                                </Popover>
                            }
                        >
                            <button className="p-2 relative hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
                                <SquareUserRound color={selectedUser_Online ? '#25cc25' : 'gray'} />
                                {usersAllList.length > 0
                                    &&
                                    <div className='absolute w-5 h-5 left-5 text-[10px] flex justify-center items-center rounded text-white bg-[#25cc25]'>{formattedCount}</div>
                                }
                            </button>
                        </Whisper>

                        <Whisper
                            trigger="click"
                            placement='bottomEnd'
                            open={opensAjouter}
                            speaker={
                                <Popover>
                                    <div>
                                        <div className='flex items-center gap-1 p-2 hover:bg-gray-100  transition-colors'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                            </svg>
                                            <button onClick={() => setcreate_groupe(true)} className="text-nowrap" aria-label="Video Call">
                                                Nouvelle groupe
                                            </button>
                                        </div>
                                    </div>

                                </Popover>
                            }
                        >
                            <button onClick={(e) => { e.stopPropagation(); setOpensAjouter(true) }} className="p-2 hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
                                <Plus className="w-5 h-5 text-gray-600" />
                            </button>
                        </Whisper>


                        <button onClick={() => setInfoUsers(true)} className="p-2 hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
                            <Settings className="w-5 h-5 text-gray-600" />
                        </button>
                    </div>
                </div>
                <div className="bg-white border-b flex justify-between items-center h-[7.5vh] w-full border-gray-200 p-1 shadow-sm">
                    <button onClick={() => { localStorage.removeItem("selectedGroup_Id"); setSelectedGroup_Id(null); setSelected_Groupe(false); setSelectedUser_Online(false); setSelectedUser_Chat(true); setSelectedUser_Group(false); setSelectedUser_Non_lue(false) }} className={`p-2 hover:bg-gray-100 relative ${selectedUser_Chat ? 'text-[#25cc25]' : null} font-extrabold rounded-full transition-colors`} aria-label="Video Call">
                        {unreadUsersCount > 0
                            &&
                            <div className='absolute w-5 h-5 left-7 text-[10px] flex justify-center items-center rounded-xl text-white bg-[#25cc25]'>{unreadUsersCount}</div>
                        }
                        Chat
                    </button>
                    <button onClick={() => { localStorage.removeItem('selectedUser'); setSelectedUser(null); setSelected_Groupe(true); setSelectedUser_Online(false); setSelectedUser_Chat(false); setSelectedUser_Group(true); setSelectedUser_Non_lue(false) }} className={`p-2 hover:bg-gray-100 ${selectedUser_Group ? 'text-[#25cc25]' : null} font-extrabold rounded-full relative transition-colors`} aria-label="Video Call">
                        {unreadUsersCount_groupe > 0
                            &&
                            <div className='absolute w-5 h-5 left-10 text-[10px] flex justify-center items-center rounded-xl text-white bg-[#25cc25]'>{unreadUsersCount_groupe}</div>
                        }

                        Groupe
                    </button>
                    <button onClick={() => { localStorage.removeItem('selectedUser'); localStorage.removeItem("selectedGroup_Id"); setSelectedGroup_Id(null); setSelectedUser(null); setSelected_Groupe(false); setSelectedUser_Online(false); setSelectedUser_Chat(false); setSelectedUser_Group(false); setSelectedUser_Non_lue(true) }} className={`p-2 hover:bg-gray-100 ${selectedUser_Non_lue ? 'text-[#25cc25]' : null} font-extrabold  rounded-full transition-colors`} aria-label="Video Call">
                        Non lues
                    </button>
                    <Whisper
                        trigger="hover"
                        placement='bottom'
                        speaker={
                            <Popover className='text-nowrap'>
                                Amie en ligne
                            </Popover>
                        }
                    >
                        <button onClick={() => { localStorage.removeItem('selectedUser'); setSelected_Groupe(false); setSelectedUser_Online(true); setSelectedUser_Chat(false); setSelectedUser_Group(false); setSelectedUser_Non_lue(false) }} className="p-2 relative hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
                            <UsersRound color={selectedUser_Online ? '#25cc25' : 'gray'} />
                            {allUsers_Online > 0
                                &&
                                <div className='absolute w-5 h-5 left-5 text-[10px] flex justify-center items-center rounded text-white bg-[#25cc25]'>{allUsers_Online}</div>
                            }
                        </button>
                    </Whisper>

                </div>
            </>
        );
    }
    const render_header_Top = () => {
        const allUsers = [...usersList];
        const selectedUserDetails = allUsers.find(user => user.id === selectedUser);

        if (!selectedUserDetails) return null;

        const { photo, name, email, etat, id } = selectedUserDetails;
        const isCurrentUser = id === parseInt(userId_login, 10);
        const isUserTyping = typingUsers.includes(id);
        return (
            <div className="flex items-center w-full pt-1 justify-between h-[10vh] bg-gray-100 rounded shadow">
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center  w-full">
                        <div className="w-10 h-10 rounded-full  mr-4">
                            {photo ? (
                                <img
                                    src={`https://rtc.eac-advisor.org/imageUsers/${photo}`}
                                    className="w-full h-full object-cover rounded-full"
                                    alt={name}
                                />
                            ) : (
                                <div className="bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full">
                                    {name.charAt(0).toUpperCase()}
                                </div>
                            )}
                        </div>
                        <div>
                            <div className="font-bold first-letter:uppercase">
                                <span className="font-normal">~ {name} ~</span>
                                {isCurrentUser && <span> (Vous)</span>}
                            </div>
                            <div className="text-[10px]">{email}</div>
                            {isUserTyping ? (
                                <div className="text-[10px] text-[blue]">En train de taper...</div>
                            ) : etat === "En ligne" ? (
                                <div className="text-[10px] text-[#25cc25]">{etat}</div>
                            ) : (
                                <div className="text-[10px] text-gray-500">{etat}</div>
                            )}

                        </div>
                    </div>

                    <div className={` justify-end ${mobile ? 'flex' : 'hidden'}`}>
                        <div onClick={() => setIsSlideMobile(true)} className='bg-gray-500 hover:bg-[#25cc25] p-1 rounded text-white cursor-pointer'>
                            <ArrowRight size={15} />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const hundleDeconnexion = () => {
        socket.emit('logout', {
            userId: parseInt(userId_login, 10),
            isPageVisible: 'offline'
        });

        localStorage.removeItem('accessToken_Data');
        localStorage.removeItem('userId');
        navig("/");
        SetIsVisitor(true);
        fetchUsersWithMessages()
        fethersGrouper_Users()

        fetchUsersWithMessages_nonLue()
    };
    useEffect(() => {
        const hundleClick = (e) => {
            setOpensAjouter(false)
        }
        window.addEventListener('click', hundleClick)
        return () => window.removeEventListener('click', hundleClick)
    }, [])


    const formatCount = (num) => {
        if (num >= 1_000_000_000_000) {
            return (num / 1_000_000_000_000).toFixed(1).replace(/\.0$/, '') + 'T'; // Trillions
        } else if (num >= 1_000_000_000) {
            return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'G'; // Billions
        } else if (num >= 1_000_000) {
            return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'; // Millions
        } else if (num >= 1_000) {
            return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K'; // Thousands
        } else {
            return num.toString(); // Less than 1000
        }
    };
    const usersCount = usersAllList.length;
    const formattedCount = formatCount(usersCount);
    // .......................................
    const [groupes, SetGroupes] = useState([])
    const [newMessag_gro, setNewMessag_gro] = useState({});
    const [groupDataMessage, setGroupDataMessage] = useState([]);

    useEffect(() => {
        const seleGroup = parseInt(selectedGroup_Ids, 10)
        fetchGroupMessages(seleGroup)
        fethersGrouper_Users()
    }, [selectedGroup_Id])
    // Gestion du clic sur un groupe
    const handleGroupClick = (group_Id) => {
        setSelectedGroup_Id(group_Id);
        fetchGroupMessages(group_Id)
        localStorage.setItem("selectedGroup_Id", JSON.stringify(group_Id));
        fethersGrouper_Users()

    };
    const fethersGrouper_Users = async () => {
        try {
            const groups = await axios.get(
                'https://rtc.eac-advisor.org/group/group_userId_message', {
                ...tokenUtile,
                params: { query: searchTerm_Group }
            }
            );
            // Obtenez les groupes
            const usersWithMessages = groups.data.users_groupe_message;
            const sortedGroups = usersWithMessages.sort((a, b) => {
                // Récupérer le dernier message pour chaque groupe
                const lastMessageA = a.messages.length > 0
                    ? new Date(a.messages[a.messages.length - 1].timestamp).getTime()
                    : null;
                const lastMessageB = b.messages.length > 0
                    ? new Date(b.messages[b.messages.length - 1].timestamp).getTime()
                    : null;

                // Trier les groupes :
                // 1. Si les deux ont des messages, comparez par timestamp
                if (lastMessageA && lastMessageB) {
                    return lastMessageB - lastMessageA;
                }

                // 2. Si l'un des deux n'a pas de message, placez celui avec des messages en premier
                if (lastMessageA) return -1;
                if (lastMessageB) return 1;

                // 3. Si aucun des deux n'a de message, gardez leur ordre initial
                return 0;
            });
            // Mettez à jour l'état avec les groupes triés



            // Comptez les groupes avec des messages non lus
            const groupsWithUnreadMessages = usersWithMessages.filter(group => {
                return group.messages.some(message => message.message_Read === 'non');
            });
            const unreadGroupCount = groupsWithUnreadMessages.length;
            setUnreadUsersCount_groupe(unreadGroupCount)
            SetGroupes(sortedGroups);
        } catch (error) {
            console.log("Il y a une erreur lors de récupération des groupes:", error);
        }
    };
    useEffect(() => {
        fethersGrouper_Users()
    }, [searchTerm_Group])
    const render_groupe = () => {



        return (
            <>
                <div className={`p-2 flex  items-center`}>
                    <input
                        type="text"
                        className="w-full px-2 py-1.5 border outline-none focus:border-[#25cc25] rounded-md"
                        placeholder="Rechercher par nom de groupe"
                        value={searchTerm_Group}
                        onChange={(e) => setSearchTerm_Group(e.target.value)}

                    />
                </div>
                {groupes && groupes.length > 0 ? groupes.map((group) => {
                    // Récupérer le dernier message
                    const lastMessage = group.messages.length > 0
                        ? group.messages[group.messages.length - 1]
                        : null;

                    // Formatage des données du dernier message
                    const lastMessageSender = lastMessage ? lastMessage.sender_name : "J'utilise BRT CHAT";
                    const lastMessageContent = lastMessage ? lastMessage.content : null;
                    const lastMessageTime = lastMessage
                        ? new Date(lastMessage.timestamp).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
                        : null;

                    // Check if any users are typing in the current group
                    const typingUser = typingUsers_group.find((groupTyping) => groupTyping.groupId === group.groupId);
                    const lastTypingUser = typingUser && typingUser.users.length > 0
                        ? typingUser.users[typingUser.users.length - 1].name
                        : null;

                    return (
                        <div
                            key={group.groupId}
                            onClick={() => { handleGroupClick(group.groupId); setIsSlideMobile(false) }}
                            className={`flex items-center w-full hover:scale-105 duration-500 cursor-pointer transition-all ${selectedGroup_Id === group.groupId ? 'border-x-2 border-[#25cc25] bg-[#25cc2533] rounded-none' : 'border-gray-50 border-y-[0.1px] hover:bg-gray-100 bg-white'} p-2 rounded relative`}
                        >

                            <div className="flex items-center justify-start h-full w-full">
                                <div className='w-10 h-10 relative'>
                                    {group.groupProfil !== null ? (
                                        <img
                                            src={`https://rtc.eac-advisor.org/imageUsers/${group.groupProfil}`}
                                            className="w-full h-full object-cover rounded-full mr-4"
                                            alt=' '
                                        />
                                    ) : (
                                        <div className='bg-green-600 w-full h-full flex items-center justify-center text-white text-[20px] font-bold rounded-full'>
                                            {group.groupName && group.groupName.charAt(0)}
                                        </div>
                                    )}
                                </div>
                                <div className="w-full relative overflow-hidden h-12 ml-1">
                                    <div className="w-[70%] text-nowrap overflow-hidden first-letter:uppercase font-bold text-ellipsis">
                                        {group.groupName}
                                    </div>


                                    {lastTypingUser ? (
                                        <div className="w-[70%] text-nowrap overflow-hidden text-[blue] text-[14px] text-ellipsis">
                                            <span> ~ {lastTypingUser}~ est train d'ecrire...</span>
                                        </div>
                                    ) :
                                        <div className="w-[70%] text-nowrap overflow-hidden text-[15px] text-ellipsis">
                                            <span className='text-gray-500'>~{lastMessageSender}~</span>: {lastMessageContent}
                                        </div>
                                    }


                                    <div className="w-full absolute top-0 items-end flex flex-col pr-2">
                                        {group.unreadCount > 0 &&
                                            <div className="bg-[#25cc25] w-5 h-5 right-1 flex justify-center items-center text-white text-[10px] rounded-full p-1">
                                                {group.unreadCount}
                                            </div>
                                        }
                                        {
                                            lastMessageTime &&
                                            <div className="text-[10px] bg-[gray] rounded text-white p-1 first-letter:uppercase">
                                                {lastMessageTime}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    );

                }) :
                    <div className='w-full pt-10'>
                        <div className="text-gray-500 flex flex-col items-center p-4">
                            <div> Aucun groupe disponible.</div>
                            <Group size={100} />
                        </div>
                        <div onClick={() => { setcreate_groupe(true); setSelectedUser_Online(false); setSelectedUser_Chat(false); setSelectedUser_Group(false); setSelectedUser_Non_lue(false) }} className="text-blue-500 text-center p-2 cursor-pointer border-2">
                            Créer un groupe
                        </div>
                    </div>
                }
            </>
        )



    };
    const render_header_Top_group = () => {
        const selectedGroupDetails = groupes.find(group => group.groupId === selectedGroup_Id);
        if (!selectedGroupDetails) return null;
        const { groupProfil, groupName, members } = selectedGroupDetails;
        const onlineMembersCount = members.filter(membre =>
            membre.etat === 'online' && membre.id !== parseInt(userId_login, 10)
        ).length;

        // Vérifier les utilisateurs qui sont en train d'écrire dans ce groupe
        const groupTypingUsers = typingUsers_group.find(group => group.groupId === selectedGroup_Id);
        const typingUsersInGroup = groupTypingUsers ? groupTypingUsers.users : [];

        return (
            <div className="flex items-center justify-between h-[10vh]  rounded shadow">
                <div className="flex items-center w-full">
                    <div className="w-10 h-10 rounded-full mr-4">
                        {groupProfil !== null ? (
                            <img
                                src={`https://rtc.eac-advisor.org/imageUsers/${groupProfil}`}
                                className="w-full h-full object-cover rounded-full"
                                alt={groupProfil}
                            />
                        ) : (
                            <div className="bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full">
                                {groupName.charAt(0).toUpperCase()}
                            </div>
                        )}
                    </div>
                    <div className='w-full'>
                        <div className='flex w-full justify-between'>
                            <div className="font-bold flex gap-1 items-center first-letter:uppercase">
                                <div className='first-letter:uppercase'>{groupName}</div>
                                {onlineMembersCount !== 0 &&
                                    <div className="text-gray-500 text-[12px]">
                                        {onlineMembersCount} en ligne
                                    </div>
                                }
                            </div>
                            <div className='flex items-center'>
                                <button className={`p-2 hover:bg-gray-100  hidden font-extrabold rounded-full transition-colors`}>
                                    <PhoneForwarded size={15} />
                                </button>
                                <div className={`justify-end ${mobile ? 'flex' : 'hidden'} w-1/2`}>
                                    <div onClick={() => setIsSlideMobile(true)} className='bg-gray-500 hover:bg-[#25cc25] p-1 rounded text-white cursor-pointer'>
                                        <ArrowRight size={15} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-1 text-gray-400 text-[13px] overflow-hidden">
                            {members && members.map((membre, index) => {
                                const isTyping = typingUsersInGroup.some(user => user.userId === membre.id);
                                return (
                                    <div key={membre.id} className='relative flex items-start'>
                                        <div className='flex flex-col items-start'>
                                            <div className='text-nowrap text-[11px] text-ellipsis overflow-hidden'>
                                                {membre.id === parseInt(userId_login, 10) ? 'Vous' : membre.nom_complet}
                                            </div>
                                            {membre.id !== parseInt(userId_login, 10) && (
                                                isTyping ? (
                                                    <div className="text-[8px] text-gray-600">
                                                        <span className="font-bold text-[blue]">Ecrire...</span>
                                                    </div>
                                                ) : (
                                                    <div className={`text-[8px] ${membre.etat === 'online' ? 'text-green-500' : 'text-gray-500'}`}>
                                                        {membre.etat === 'online' ? '(En ligne)' : membre.lastSeen !== null ? new Date(membre.lastSeen).toLocaleTimeString("fr-FR") : '(Hors ligne)'}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        {index < members.length - 1 && <span className="mx-0.2">,</span>}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const handleInputChange_Group = (groupId, value) => {


        setNewMessag_gro((prevMessages) => ({
            ...prevMessages,
            [groupId]: value,
        }));
        localStorage.setItem('newMessage_group', JSON.stringify({ ...newMessag_gro, [groupId]: value }))

        if (!isTyping) {
            setIsTyping(true);
            socket.emit('typing_group', { isTyping: true, groupId: parseInt(selectedGroup_Ids, 10) });
        }

        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            setIsTyping(false);
            socket.emit('typing_group', { isTyping: false, groupId: parseInt(selectedGroup_Ids, 10) });
        }, typingTimeout);

    };
    const handleSendMessageGroupe = async (groupId) => {
        const messageContent = newMessag_gro[groupId];

        if (!messageContent || !groupId) {
            toast.warning("Veuillez saisir un message.");
            return false;
        }

        const tempId = `temp_${Date.now()}`;
        console.log("tempId:", tempId)

        // Ajouter le message temporaire
        setGroupDataMessage((prevMessages) => ({
            ...prevMessages,
            [groupId]: [
                ...(prevMessages[groupId] || []),
                {
                    id: tempId,
                    content: messageContent,
                    sender_Id: parseInt(userId_login, 10),
                    isTemporary: true,
                    timestamp: new Date(),
                },
            ],
        }));

        // Réinitialiser le champ de saisie
        setNewMessag_gro((prevNewMessages) => ({
            ...prevNewMessages,
            [groupId]: '',
        }));
        localStorage.setItem('newMessage_group', JSON.stringify({ ...newMessag_gro, [groupId]: '' }));

        try {
            const serverMessage = await messageService.create({
                content: messageContent,
                sender_Id: parseInt(userId_login, 10),
                groupId: groupId,
            });

            // Remplacer le message temporaire par celui confirmé par le serveur
            setGroupDataMessage((prevMessages) => ({
                ...prevMessages,
                [groupId]: (prevMessages[groupId] || []).map((msg) =>
                    msg.id === tempId ? serverMessage : msg
                ),
            }));
        } catch (error) {
            console.error("Erreur lors de l'envoi du message :", error);

            // Supprimer le message temporaire en cas d'erreur
            setGroupDataMessage((prevMessages) => ({
                ...prevMessages,
                [groupId]: (prevMessages[groupId] || []).filter(
                    (msg) => msg.id !== tempId
                ),
            }));

            toast.error("Erreur lors de l'envoi du message.");
        }
    };
    const fetchGroupMessages = async (groupId) => {
        if (!groupId) {
            console.error("Veuillez sélectionner un groupe pour voir les messages.");
            return false;
        }
        try {
            const response = await axios.get(`https://rtc.eac-advisor.org/group/group_userId_message_detail/${groupId}`, tokenUtile);
            setGroupDataMessage((prevMessages) => ({
                ...prevMessages,
                [groupId]: response.data.users_groupe_message_detail.messages,
            }));

        } catch (err) {
            console.log("Erreur lors de la récupération des messages du groupe.", err);
        }
    };
    const [delayedMessages, setDelayedMessages] = useState({});
    useEffect(() => {
        const timeoutIds = [];
        Object.keys(groupDataMessage).forEach((groupId) => {
            timeoutIds.push(
                setTimeout(() => {
                    setDelayedMessages((prev) => ({
                        ...prev,
                        [groupId]: groupDataMessage[groupId],
                    }));
                }, 300)
            );
        });

        return () => timeoutIds.forEach((id) => clearTimeout(id));
    }, [groupDataMessage]);
    const renderGroupMessages = (groupId) => {
        const messages = delayedMessages[groupId] || [];

        const getDateLabel = (dateString) => {
            const today = new Date();
            const date = new Date(dateString);
            const diffTime = today.setHours(0, 0, 0, 0) - date.setHours(0, 0, 0, 0);
            const diffDays = diffTime / (1000 * 60 * 60 * 24);

            if (diffDays === 0) return "Aujourd'hui";
            if (diffDays === 1) return "Hier";
            if (diffDays === 2) return "Avant-hier";

            return `Le ${date.toLocaleDateString("fr-FR")}`;
        };

        const groupedMessages = messages.reduce((acc, message) => {
            const dateLabel = getDateLabel(message.timestamp);
            if (!acc[dateLabel]) acc[dateLabel] = [];
            acc[dateLabel].push(message);
            return acc;
        }, {});

        return (
            <>
                {Object.keys(groupedMessages).map((dateLabel) => (
                    <div key={dateLabel}>
                        <div className="text-center text-gray-500 text-sm mb-2">{dateLabel}</div>
                        {groupedMessages[dateLabel].map((message, index) => {
                            const previousMessage = groupedMessages[dateLabel][index - 1];
                            const isSameSenderAsPrevious =
                                previousMessage && previousMessage.sender_id === message.sender_id;

                            return (
                                <div
                                    key={message.id || index}
                                    className={`flex relative ${((message.sender_id === parseInt(userId_login, 10)) || (message.isTemporary && message.id)) ? "justify-end" : "justify-start"
                                        }`}
                                >
                                    <div
                                        className={`mb-3 p-1 relative rounded ${((message.sender_id === parseInt(userId_login, 10)) || (message.isTemporary && message.id))
                                            ? "bg-[#25cc25] max-w-[90%]"
                                            : "bg-gray-300 max-w-[90%]"
                                            }`}
                                    >
                                        {!isSameSenderAsPrevious && (
                                            <div className="flex items-center pt-2 mb-2">
                                                {((message.sender_id === parseInt(userId_login, 10)) || message.isTemporary) && (
                                                    <div className="w-7 h-7">
                                                        {message.sender_Profile ? (
                                                            <img
                                                                src={`https://rtc.eac-advisor.org/imageUsers/${message.sender_Profile}`}
                                                                className="w-full h-full object-cover rounded-full"
                                                                alt={message.sender_name || "Utilisateur"}
                                                            />
                                                        ) : (
                                                            <div className="bg-[#25cc25] w-full h-full flex items-center justify-center text-white text-sm font-bold rounded-full">
                                                                {message.sender_name
                                                                    ? message.sender_name.charAt(0)
                                                                    : "?"}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {((message.sender_id === parseInt(userId_login, 10)) || message.isTemporary) ?
                                                    <></> :
                                                    <div className="ml-2">
                                                        <h4 className="text-sm font-bold">
                                                            {message.sender_name}
                                                        </h4>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                        <div
                                            className={`whitespace-break-spaces ${((message.sender_id === parseInt(userId_login, 10)) || message.isTemporary)
                                                ? "text-white"
                                                : ""
                                                }`}
                                        >
                                            {typeof message.content === "string" ? message.content : "Message invalide"}
                                        </div>
                                        <div className="text-[10px] mt-2 text-gray-500 italic flex items-center justify-end gap-2">
                                            <div
                                                className={`text-xs ${((message.sender_id === parseInt(userId_login, 10)) || message.isTemporary)
                                                    ? "text-white"
                                                    : "text-gray-500"
                                                    }`}
                                            >
                                                {new Date(message.timestamp).toLocaleTimeString("fr-FR")}
                                            </div>
                                            {((message.sender_id === parseInt(userId_login, 10)) || message.isTemporary) && (
                                                <span className="text-xs text-gray-400 ml-2">
                                                    {message.message_Read === "oui" ?
                                                        <CheckCheck size={15} color='white' /> :
                                                        message.isTemporary ?
                                                            <Clock8 size={15} color='gray' /> :
                                                            <CheckCheck size={15} color='gray' />}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
                {renderTypingUsers(selectedGroup_Id)}
            </>
        )
    }
    const markMessagesAsRead_group = async (messageId) => {
        const dataSend = {
            message_id: messageId,
            userId: parseInt(userId_login, 10),
            groupId: parseInt(selectedGroup_Ids, 10),
        }

        try {
            await axios.patch('https://rtc.eac-advisor.org/group/messageRead', dataSend);
        } catch (error) {
            console.error('Error marking message as read:', error.response?.data || error.message);
        }
    };
    useEffect(() => {
        if (!selectedGroup_Ids) {
            console.warn('No selected group ID found in localStorage.');
            return;
        }

        const groupMessages = groupDataMessage[selectedGroup_Ids] || [];
        const currentUserId = parseInt(userId_login, 10);
        const messageIds = groupMessages
            .filter(message => message.sender_id !== currentUserId)
            .map(message => message.id);
        if (messageIds.length > 0) {
            markMessagesAsRead_group(messageIds);
        }
    }, [groupDataMessage, selectedGroup_Ids, userId_login]);
    const renderTypingUsers = (groupId) => {
        const groupTypingData = typingUsers_group.find(group => group.groupId === groupId);
        if (!groupTypingData) return null;
        const typingUsers = groupTypingData.users;

        if (typingUsers.length === 0) return null;

        return (
            <div className="text-gray-500 text-[10px] mt-2 mb-5">
                {typingUsers.map(user => (
                    <div key={user.userId} className="text-blue-500 text-[13px] font-semibold">
                        {user.name} est en train de taper...
                    </div>
                ))}
            </div>
        );
    };
    useEffect(() => {
        const hundelWindowClick = () => {
            setShowMenu(false)
        }
        window.addEventListener('click', hundelWindowClick)
        return window.removeEventListener('click', hundelWindowClick)
    }, [])
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (messages_groupeEndRef.current) {
            messages_groupeEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, groupDataMessage]);
    useEffect(() => {
        const handleConnect = async () => {
            await fetchUsersWithMessages();
            await fetchUsersWithMessages_nonLue();

            await fethersGrouper_Users();
        };

        socket.on('connect', handleConnect);
        return () => {
            socket.off('connect', handleConnect);
        };
    }, [socket]);
    useEffect(() => {
        const handleUserTypingMessages = (data) => {
            setTypingUsers((prevTypingUsers) => [...prevTypingUsers, data.userId]);
            setTimeout(() => {
                setTypingUsers((prevTypingUsers) => prevTypingUsers.filter((userId) => userId !== data.userId));
            }, 2000);
        };

        feathersClient.service("users").on("user_typing", handleUserTypingMessages);
        return () => {
            feathersClient.service("users").off("user_typing", handleUserTypingMessages);
        };
    }, []);
    useEffect(() => {
        const handleGroupTyping = (data) => {
            const { userId, groupId, isTyping, name } = data;
            setTypingUsers_groupe((prevTypingUsers) => {
                const updatedTypingUsers = [...prevTypingUsers];
                // Find the group in the array or add a new one
                let group = updatedTypingUsers.find((group) => group.groupId === groupId);
                if (!group) {
                    group = { groupId, users: [] };
                    updatedTypingUsers.push(group);
                }

                if (isTyping) {
                    // Add the user to the typing list if they are not already there
                    if (!group.users.some((user) => user.userId === userId)) {
                        group.users.push({ userId, name });
                    }
                } else {
                    // Remove the user from the typing list
                    group.users = group.users.filter((user) => user.userId !== userId);
                }

                // If no one is typing in the group, remove the group from the array
                if (group.users.length === 0) {
                    updatedTypingUsers.splice(updatedTypingUsers.indexOf(group), 1);
                }

                return updatedTypingUsers;
            });

            // Optionally, remove the user after 2 seconds
            if (isTyping) {
                setTimeout(() => {
                    setTypingUsers_groupe((prevTypingUsers) => {
                        const updatedTypingUsers = [...prevTypingUsers];
                        const group = updatedTypingUsers.find((group) => group.groupId === groupId);
                        if (group) {
                            group.users = group.users.filter((user) => user.userId !== userId);
                            if (group.users.length === 0) {
                                updatedTypingUsers.splice(updatedTypingUsers.indexOf(group), 1);
                            }
                        }
                        return updatedTypingUsers;
                    });
                }, 2000);
            }
        }
        feathersClient.service("users").on("user_typing_group", handleGroupTyping);

        return () => {
            feathersClient.service("users").off("user_typing_group", handleGroupTyping);
        };
    }, []);





    return (
        <div className="flex relative h-screen">
            <div className={`bg-gray-200 h-[100vh]  ${mobile ? 'hidden' : 'w-[30em]'} overflow-hidden`}>
                <div className='h-[15vh]'>{render_header_Slide()}</div>
                <div className='overflow-y-auto overflow-x-hidden h-[85vh]'>
                    <div>
                        {selected_groupe ? (
                            render_groupe()
                        ) :
                            selectedUser_Non_lue ? (
                                render_utilisateur_message_non_lue()
                            ) : selectedUser_Online ? (
                                <>
                                    {render_utilisateur_online()}
                                </>
                            ) : (
                                <>
                                    {render_utilisateur()}
                                </>
                            )
                        }
                    </div>
                </div>

            </div>

            <div className={` ${mobile ? 'w-full p-1' : 'w-3/4 p-4'}`}>
                {selectedUser && !selected_groupe ? (
                    <>
                        {render_header_Top()}
                        <div className="p-2 h-[75vh] bg-gray-50 overflow-y-auto overflow-x-hidden">

                            {renderMessages()}
                            <div ref={messagesEndRef} />
                        </div>


                        <div className="flex items-start mb-0.5 sm:mb-1">

                            <textarea
                                value={newMessages[selectedUser] || ''}
                                onChange={(e) => handleInputChange(selectedUser, e.target.value)}
                                className="flex-1 p-2 bg-transparent resize-none max-sm:text-[13px]  border outline-none"
                                rows="3"
                                placeholder={`Écrivez  à ${parseInt(userId_login) === selectedUser ? 'Vous' : usersList.find(user => user.id === selectedUser)?.name}`}
                            />

                            <button
                                onClick={() => handleSendMessage(selectedUser)}
                                className="ml-2 bg-[#25cc25] text-white sm:p-2 p-1 rounded-md"
                            >
                                Envoyer
                            </button>

                        </div>
                    </>
                ) : selected_groupe ? (
                    selectedGroup_Id ? (
                        <>
                            {render_header_Top_group()}
                            <div className="p-2 h-[75vh] bg-gray-50 overflow-y-auto overflow-x-hidden">
                                {renderGroupMessages(selectedGroup_Id)}
                                <div ref={messages_groupeEndRef} />
                            </div>

                            <div className="flex items-start mb-1">
                                <textarea
                                    value={newMessag_gro[selectedGroup_Id] || ''}
                                    onChange={(e) => handleInputChange_Group(selectedGroup_Id, e.target.value)}
                                    className="flex-1 p-2 bg-transparent resize-none border outline-none"
                                    rows="3"
                                    placeholder={`Écrivez votre message à ${groupes.find(group => group.groupId === selectedGroup_Id)?.groupName}`}
                                />
                                <button
                                    onClick={() => handleSendMessageGroupe(selectedGroup_Id)}
                                    className="ml-2 bg-[#25cc25] text-white p-2 rounded-md"
                                >
                                    Envoyer
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="flex items-center justify-center flex-col h-full text-gray-500">
                            <div className="text-[3em] text-gray-200 font-bold text-nowrap uppercase font-serif">
                                btr chat
                            </div>
                            <p>Sélectionnez un groupe pour voir les messages</p>
                        </div>
                    )
                ) : (
                    <div className="flex items-center justify-center flex-col h-full text-gray-500">
                        <div className="text-[3em] text-gray-200 font-bold text-nowrap uppercase font-serif">
                            btr chat
                        </div>
                        <p>Sélectionnez un utilisateur pour voir les messages</p>
                    </div>
                )}
            </div>

            <Drawer closeButton={false} placement='left' size={`${mobile ? 'full' : 'xs'}`} open={mobile && isSlideMobile} >
                <Drawer.Body>
                    <div className={`bg-gray-200 w-full`}>
                        <div className='h-[15vh]'>{render_header_Slide()}</div>
                        <div className='overflow-y-auto overflow-x-hidden h-[85vh]'>
                            <div>
                                {selected_groupe ? (
                                    render_groupe()
                                ) :
                                    selectedUser_Non_lue ? (
                                        render_utilisateur_message_non_lue()
                                    ) : selectedUser_Online ? (
                                        render_utilisateur_online()
                                    ) : (
                                        render_utilisateur()
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </Drawer.Body>
            </Drawer>

            <Drawer placement='left' size={`${mobile ? 'full' : 'xs'}`} open={infoUsers} onClose={() => setInfoUsers(false)}>
                <Drawer.Header>
                    <Drawer.Title className='font-bold text-center'>Paramètres</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <Compte hundleDeconnexion={hundleDeconnexion} compte={compte} />
                </Drawer.Body>
            </Drawer>

            <Drawer placement='left' closable={false} size={`${mobile ? 'full' : 'xs'}`} open={create_groupe} onClose={() => setcreate_groupe(false)}>
                <Drawer.Body>
                    <Create_Groupe userIds={parseInt(userId_login, 10)} usersAllList={usersAllList} setcreate_groupe={setcreate_groupe} />
                </Drawer.Body>
            </Drawer>
        </div >
    );
};

export default Chat_general;




















// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable react/jsx-pascal-case */
// import React, { useEffect, useRef, useState } from 'react';
// import { socket } from '../feathersClient/feathersClient';
// import { toast } from 'react-toastify';

// import { Drawer } from 'rsuite';
// import { useNavigate } from 'react-router-dom';
// import Compte from './formilaire/Compte';



// import { useThemes } from '../UserContext/UserContext';

// import Create_Groupe from './Create_Groupe';

// const Chat_general = () => {
//     const { render_utilisateur_message_non_lue, selectedUser_Non_lue, render_groupe, selected_groupe,
//         mobile, render_header_Slide, SetIsVisitor, infoUsers, setInfoUsers, selectedUser_Online, render_utilisateur_online,
//         render_utilisateur, selectedUser, render_header_Top, renderMessages, messagesEndRef, newMessages, handleInputChange,
//         userId_login, usersList, handleSendMessage, selectedGroup_Id, render_header_Top_group,
//         renderGroupMessages, messages_groupeEndRef, newMessag_gro, handleInputChange_Group, groupes, handleSendMessageGroupe,
//         isSlideMobile, create_groupe, setcreate_groupe, usersAllList, fetchUsersWithMessages, fethersGrouper_Users,
//         fetchUsersWithMessages_nonLue,
//         compte } = useThemes()
//     const navig = useNavigate()

//     const hundleDeconnexion = () => {
//         socket.emit('logout', {
//             userId: parseInt(userId_login, 10),
//             isPageVisible: 'offline'
//         });

//         localStorage.removeItem('accessToken_Data');
//         localStorage.removeItem('userId');
//         navig("/");
//         SetIsVisitor(true);
//         fetchUsersWithMessages()
//         fethersGrouper_Users()

//         fetchUsersWithMessages_nonLue()
//     };


//     return (
//         <div className="flex relative h-screen">
//             <div className={`bg-gray-200 h-[100vh]  ${mobile ? 'hidden' : 'w-[30em]'} overflow-hidden`}>
//                 <div className='h-[15vh]'>{render_header_Slide()}</div>
//                 <div className='overflow-y-auto overflow-x-hidden h-[85vh]'>
//                     <div>
//                         {selected_groupe ? (
//                             render_groupe()
//                         ) :
//                             selectedUser_Non_lue ? (
//                                 render_utilisateur_message_non_lue()
//                             ) : selectedUser_Online ? (
//                                 <>
//                                     {render_utilisateur_online()}
//                                 </>
//                             ) : (
//                                 <>
//                                     {render_utilisateur()}
//                                 </>
//                             )
//                         }
//                     </div>
//                 </div>

//             </div>

//             <div className={` ${mobile ? 'w-full p-1' : 'w-3/4 p-4'}`}>
//                 {selectedUser && !selected_groupe ? (
//                     <>
//                         {render_header_Top()}
//                         <div className="p-2 h-[75vh] bg-gray-50 overflow-y-auto overflow-x-hidden">

//                             {renderMessages()}
//                             <div ref={messagesEndRef} />
//                         </div>


//                         <div className="flex items-start mb-0.5 sm:mb-1">

//                             <textarea
//                                 value={newMessages[selectedUser] || ''}
//                                 onChange={(e) => handleInputChange(selectedUser, e.target.value)}
//                                 className="flex-1 p-2 bg-transparent resize-none max-sm:text-[13px]  border outline-none"
//                                 rows="3"
//                                 placeholder={`Écrivez  à ${parseInt(userId_login) === selectedUser ? 'Vous' : usersList.find(user => user.id === selectedUser)?.name}`}
//                             />

//                             <button
//                                 onClick={() => handleSendMessage(selectedUser)}
//                                 className="ml-2 bg-[#25cc25] text-white sm:p-2 p-1 rounded-md"
//                             >
//                                 Envoyer
//                             </button>

//                         </div>
//                     </>
//                 ) : selected_groupe ? (
//                     selectedGroup_Id ? (
//                         <>
//                             {render_header_Top_group()}
//                             <div className="p-2 h-[75vh] bg-gray-50 overflow-y-auto overflow-x-hidden">
//                                 {renderGroupMessages(selectedGroup_Id)}
//                                 <div ref={messages_groupeEndRef} />
//                             </div>

//                             <div className="flex items-start mb-1">
//                                 <textarea
//                                     value={newMessag_gro[selectedGroup_Id] || ''}
//                                     onChange={(e) => handleInputChange_Group(selectedGroup_Id, e.target.value)}
//                                     className="flex-1 p-2 bg-transparent resize-none border outline-none"
//                                     rows="3"
//                                     placeholder={`Écrivez votre message à ${groupes.find(group => group.groupId === selectedGroup_Id)?.groupName}`}
//                                 />
//                                 <button
//                                     onClick={() => handleSendMessageGroupe(selectedGroup_Id)}
//                                     className="ml-2 bg-[#25cc25] text-white p-2 rounded-md"
//                                 >
//                                     Envoyer
//                                 </button>
//                             </div>
//                         </>
//                     ) : (
//                         <div className="flex items-center justify-center flex-col h-full text-gray-500">
//                             <div className="text-[3em] text-gray-200 font-bold text-nowrap uppercase font-serif">
//                                 btr chat
//                             </div>
//                             <p>Sélectionnez un groupe pour voir les messages</p>
//                         </div>
//                     )
//                 ) : (
//                     <div className="flex items-center justify-center flex-col h-full text-gray-500">
//                         <div className="text-[3em] text-gray-200 font-bold text-nowrap uppercase font-serif">
//                             btr chat
//                         </div>
//                         <p>Sélectionnez un utilisateur pour voir les messages</p>
//                     </div>
//                 )}
//             </div>

//             <Drawer closeButton={false} placement='left' size={`${mobile ? 'full' : 'xs'}`} open={mobile && isSlideMobile} >
//                 <Drawer.Body>
//                     <div className={`bg-gray-200 w-full`}>
//                         <div className='h-[15vh]'>{render_header_Slide()}</div>
//                         <div className='overflow-y-auto overflow-x-hidden h-[85vh]'>
//                             <div>
//                                 {selected_groupe ? (
//                                     render_groupe()
//                                 ) :
//                                     selectedUser_Non_lue ? (
//                                         render_utilisateur_message_non_lue()
//                                     ) : selectedUser_Online ? (
//                                         render_utilisateur_online()
//                                     ) : (
//                                         render_utilisateur()
//                                     )
//                                 }
//                             </div>
//                         </div>

//                     </div>
//                 </Drawer.Body>
//             </Drawer>

//             <Drawer placement='left' size={`${mobile ? 'full' : 'xs'}`} open={infoUsers} onClose={() => setInfoUsers(false)}>
//                 <Drawer.Header>
//                     <Drawer.Title className='font-bold text-center'>Paramètres</Drawer.Title>
//                 </Drawer.Header>
//                 <Drawer.Body>
//                     <Compte hundleDeconnexion={hundleDeconnexion} compte={compte} />
//                 </Drawer.Body>
//             </Drawer>

//             <Drawer placement='left' closable={false} size={`${mobile ? 'full' : 'xs'}`} open={create_groupe} onClose={() => setcreate_groupe(false)}>
//                 <Drawer.Body>
//                     <Create_Groupe userIds={parseInt(userId_login, 10)} usersAllList={usersAllList} setcreate_groupe={setcreate_groupe} />
//                 </Drawer.Body>
//             </Drawer>
//         </div >
//     );
// };

// export default Chat_general;

// /* eslint-disable no-use-before-define */
// /* eslint-disable no-unused-vars */
// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
// import { feathersClient, socket } from '../feathersClient/feathersClient';
// import axios from 'axios';
// import { toast } from 'react-toastify';

// import { format, isSameDay, isToday, isYesterday, subDays, differenceInDays } from 'date-fns';
// import { fr } from 'date-fns/locale';

// import { Copy, Eye, ArrowRightCircle, Share2, ArrowLeft, CheckCheck, Clock7, Clock8, Dot, Edit, Mail, MessageCircleOff, MessageSquare, MoreVertical, Phone, PhoneForwarded, Pin, Plus, PlusCircle, Search, Settings, SquareUserRound, Trash2, User, UserPlus, UserRoundPlus, UsersRound, Video, X, ArrowRight, HeadphoneOff, Group, XCircle, UserRoundSearch } from 'lucide-react';
// import { Drawer, Dropdown, Popover, Whisper, Menu, IconButton } from 'rsuite';


// const UserContext = createContext();


// export const ContextPovider = ({ children }) => {

//   const [isVisitor, SetIsVisitor] = useState(false);
//   const [isUsers, SetIsUsers] = useState(false);
//   const [isAdmin, SetIsAdmin] = useState(false);
//   const [isAdminSuper, SetIsAdminSuper] = useState(false);
//   const [isSincrire, SetIsSincrire] = useState(false);

//   const userId = localStorage.getItem('userId');
//   const Tokeni = localStorage.getItem('accessToken_Data');
//   const tokenUtile = {
//     headers: { Authorization: `Bearer ${Tokeni}` },
//   };
//   // ......................users..................
//   const [infoUsers, setInfoUsers] = useState(false)
//   const [idUsers, GetIdUsers] = useState(null)
//   const [nodifi_en_Contact, Getnodifi_en_Contact] = useState({})


//   const [compte, setCompte] = useState({});
//   const [create_groupe, setcreate_groupe] = useState(false);
//   const [opensAjouter, setOpensAjouter] = useState(false);


//   const usersService = feathersClient.service('users');
//   const messageService = feathersClient.service('message');
//   const userId_login = localStorage.getItem('userId');
//   const messagesEndRef = useRef(null);
//   const messages_groupeEndRef = useRef(null);


//   const [usersList_online_getted, setUsersList_online_getted] = useState([]);

//   const [usersAllList, setUsersAllList] = useState([])
//   const [usersList, setUsersList] = useState([])
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [selectedGroup_Id, setSelectedGroup_Id] = useState(null);
//   const [selected_groupe, setSelected_Groupe] = useState(false);
//   const [selectedUser_Online, setSelectedUser_Online] = useState(false);
//   const [selectedUser_Chat, setSelectedUser_Chat] = useState(true);
//   const [selectedUser_Group, setSelectedUser_Group] = useState(false);
//   const [selectedUser_Non_lue, setSelectedUser_Non_lue] = useState(false);
//   const selectedGroup_Ids = localStorage.getItem("selectedGroup_Id")
//   const [typingUsers, setTypingUsers] = useState([]);
//   const [typingUsers_group, setTypingUsers_groupe] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [searchTerm_Group, setSearchTerm_Group] = useState('');
//   const [unreadUsersCount, setUnreadUsersCount] = useState(0);
//   const [unreadUsersCount_groupe, setUnreadUsersCount_groupe] = useState(0);
//   const [popoverOpenUserId, setPopoverOpenUserId] = useState(null);
//   const [showMenu, setShowMenu] = useState(false);
//   const [showLecteur, setShowLecteur] = useState(false)
//   const [mobile, setMobile] = useState(window.innerWidth < 700);
//   const [isSlideMobile, setIsSlideMobile] = useState(true);

//   const [isTyping, setIsTyping] = useState(false);;
//   const typingTimeout = 3000;
//   let typingTimer = null;
//   useEffect(() => {
//     const handleSize = () => {
//       setMobile(window.innerWidth < 700);
//     };

//     window.addEventListener('resize', handleSize);
//     return () => {
//       window.removeEventListener('resize', handleSize);
//     };
//   }, []);
//   const handlePopoverOpen = (userId) => {
//     setPopoverOpenUserId(userId);
//   };
//   const handlePopoverClose = () => {
//     setPopoverOpenUserId(null);
//   };
//   useEffect(() => {
//     const handleVisibilityChange = async () => {
//       if (document.hidden) {
//         socket.emit('visibility_change', { isPageVisible: 'oui' });
//         await fetchUsersWithMessages();
//         await fetchUsersWithMessages_nonLue();

//         await fethersGrouper_Users();
//       } else {
//         await fetchUsersWithMessages();
//         await fetchUsersWithMessages_nonLue();

//         await fethersGrouper_Users();
//         socket.emit('visibility_change', { isPageVisible: 'non' });

//       }
//     };

//     document.addEventListener('visibilitychange', handleVisibilityChange);

//     socket.on('changeStateOnline_offline', () => {
//       fetchUsersWithMessages()
//       fetchUsersWithMessages_nonLue()

//       fethersGrouper_Users()
//     });

//     return () => {
//       socket.off('changeStateOnline_offline');
//       document.removeEventListener('visibilitychange', handleVisibilityChange);
//     };
//   }, [socket]);

//   const dataUserConnected = async () => {
//     await axios.get(`https://rtc.eac-advisor.org/users/${parseInt(userId_login, 10)}`)
//       .then((reponse) => {
//         setCompte(reponse.data)
//       })
//       .catch((error) => {
//         console.log("Il y a une erreur lors de la recuperation des information d'un utilisateur connectes", error)
//       })
//   }
//   useEffect(() => {
//     dataUserConnected()
//   }, [userId_login])

//   useEffect(() => {
//     const updateCompte = (data) => {
//       dataUserConnected();
//     };
//     socket.on('modifier_compte', updateCompte);
//     return () => {
//       socket.off('modifier_compte', updateCompte);
//     };
//   }, [socket]);
//   useEffect(() => {

//     const handleUsersCreated = (newUsers) => {
//       setUsersAllList((prevUsers) => {
//         if (!Array.isArray(prevUsers)) {
//           return [newUsers];
//         }
//         return [...prevUsers, newUsers];
//       });
//       fetchUsersWithMessages()

//     };
//     const feathersUser_with_Messages = async () => {
//       const user_user = await usersService.find();
//       setUsersAllList(user_user);

//     };
//     feathersUser_with_Messages()
//     feathersClient.service('users').on('created', handleUsersCreated);
//     return () => {
//       feathersClient.service('users').off('created', handleUsersCreated);
//     };
//   }, []);

//   useEffect(() => {
//     if (selectedUser) {
//       fetchMessages(selectedUser);
//     }
//     const seleGroup = parseInt(selectedGroup_Ids, 10)
//     fetchGroupMessages(seleGroup)

//     fetchUsersWithMessages()
//     fethersGrouper_Users()
//     fetchUsersWithMessages_nonLue()

//   }, [selectedUser, selectedGroup_Ids]);
//   useEffect(() => {
//     const savedUser = JSON.parse(localStorage.getItem('selectedUser'));
//     const savedMessages = JSON.parse(localStorage.getItem('messages')) || {};
//     setMessages(savedMessages);
//     if (savedUser) {
//       setSelectedUser(savedUser);
//       fetchMessages(savedUser);
//     }
//     const seleGroup = parseInt(selectedGroup_Ids, 10)
//     fetchGroupMessages(seleGroup)
//     fetchUsersWithMessages();
//     fethersGrouper_Users()
//       ;
//     fetchUsersWithMessages_nonLue()
//   }, [selectedUser, selectedGroup_Ids]);
//   const handleUserClick = (userId) => {
//     if (userId) {
//       setSelectedUser(userId)
//       localStorage.setItem('selectedUser', JSON.stringify(userId));
//       fetchMessages(userId);
//       markAllMessagesAsRead(userId);
//     }
//   };
//   const render_utilisateur = () => {
//     return (
//       <div >
//         <div className={`p-2 flex  items-center`}>
//           <input
//             type="text"
//             className="w-full px-2 py-1.5 border outline-none focus:border-[#25cc25] rounded-md"
//             placeholder="Rechercher par nom ou email"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}

//           />
//         </div>
//         {
//           usersList && usersList.length > 0 ? usersList.map((user) => (
//             <div
//               key={user.id}
//               onClick={(e) => { handleUserClick(user.id); setIsSlideMobile(false); e.stopPropagation() }}
//               className={`flex items-center w-full hover:scale-105  duration-500   cursor-pointer  transition-all ${selectedUser === user.id ? 'border-x-2  border-[#25cc25]  bg-[#25cc2533] rounded-none' : 'border-gray-50 border-y-[0.1px] hover:bg-gray-100 bg-white'} p-2 rounded relative`}
//             >


//               <div className="flex items-center justify-start h-full w-full">
//                 <div className='w-10 h-10 relative'>
//                   {user.photo !== null ? (
//                     <img
//                       src={`https://rtc.eac-advisor.org/imageUsers/${user.photo}`}
//                       className=" w-full h-full object-cover rounded-full mr-4"
//                       alt='     '
//                     />
//                   ) : (
//                     <div className='bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full'>
//                       {user.name && user.name.charAt(0)}
//                     </div>
//                   )}

//                   {user.etat === "En ligne" && (
//                     <span className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-white" />
//                   )}
//                 </div>
//                 <div className="w-full relative overflow-hidden h-12 ml-1">
//                   <div className="w-[70%] text-nowrap overflow-hidden text-xs text-ellipsis text-gray-500">
//                     {user.id === parseInt(userId_login, 10) ? <>Vous</> : <>~{user.name}~</>}
//                   </div>

//                   <div className="w-full absolute top-0 items-end flex flex-col pr-2">
//                     {user.lastMessageTime && (
//                       <div className="text-[10px] bg-[gray] rounded text-white p-1 first-letter:uppercase">
//                         {timeSince(user.lastMessageTime)}
//                       </div>
//                     )}
//                     {user.unreadMessagesSent > 0 && (
//                       <div className="bg-[#25cc25] w-5 h-5 right-1 flex justify-center items-center text-white text-[10px] rounded-full p-1">
//                         {user.unreadMessagesSent}
//                       </div>
//                     )}
//                   </div>

//                   <div className="text-gray-500 flex flex-col justify-center">
//                     {(newMessages[user.id] && selectedUser !== user.id) ? (
//                       <div className="flex">
//                         <div className="text-[red]">Brouillon:</div>
//                         <div className="text-nowrap overflow-hidden ml-1 text-ellipsis w-[60%]">
//                           {newMessages[user.id]}
//                         </div>
//                       </div>
//                     ) : (
//                       <div className='flex items-center'>
//                         <div className={`${selectedUser === user.id ? 'text-gray-500' : 'text-gray-400'} text-[11px] text-ellipsis w-[80%] overflow-hidden text-nowrap`}>
//                           {user.lastMessage}

//                         </div>
//                         {user.lastMessage !== "j'utilise le chat brt" && user.isSendId ? (
//                           <div className="ml-2 relative top-1">
//                             {user.unreadMessagesReceived === 0 ? (
//                               <CheckCheck size={13} color="#25cc25" />
//                             ) : (
//                               <CheckCheck size={13} color="gray" />
//                             )}
//                           </div>
//                         ) : null}
//                       </div>
//                     )}
//                     <div className="">

//                       {typingUsers.includes(user.id) ? (
//                         <div className="flex items-center">
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             className="h-2 w-2 text-green-500"
//                             fill="currentColor"
//                             viewBox="0 0 20 20"
//                           >
//                             <circle cx="10" cy="10" r="5" />
//                           </svg>
//                           <span className="ml-1 text-[blue] text-[9px]">En train de taper...</span>
//                         </div>


//                       ) : user.etat === "En ligne" ? (
//                         <div className="flex items-center">
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             className="h-2 w-2 text-green-500"
//                             fill="currentColor"
//                             viewBox="0 0 20 20"
//                           >
//                             <circle cx="10" cy="10" r="5" />
//                           </svg>
//                           <span className="ml-1 text-green-500 text-[9px]">En ligne</span>
//                         </div>
//                       ) : (
//                         <div className="flex items-center">
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             className="h-2 w-2 text-gray-400"
//                             fill="currentColor"
//                             viewBox="0 0 20 20"
//                           >
//                             <circle cx="10" cy="10" r="5" />
//                           </svg>
//                           <span className="ml-1 text-gray-400 text-[9px]">
//                             <div className="text-[10px] text-gray-500">
//                               {user.etat}
//                             </div>
//                           </span>
//                         </div>
//                       )}
//                     </div>

//                   </div>
//                 </div>


//                 <Whisper
//                   placement="bottom"
//                   trigger="click"
//                   open={popoverOpenUserId === user.id}
//                   onClose={handlePopoverClose}
//                   speaker={
//                     <Popover>
//                       <div className="flex flex-col gap-2">
//                         <button
//                           className="flex items-center gap-2 text-gray-700 hover:text-green-600"
//                           onClick={() => {
//                             handlePopoverClose();
//                           }}
//                         >
//                           <PlusCircle size={16} /> Ajouter
//                         </button>

//                         <button
//                           className="flex items-center gap-2 text-gray-700 hover:text-red-600"
//                           onClick={() => {
//                             handlePopoverClose();
//                           }}
//                         >
//                           <MessageSquare size={16} /> Supprimer discussion
//                         </button>
//                         <button
//                           className="flex items-center gap-2 text-gray-700 hover:text-red-600"
//                           onClick={() => {
//                             handlePopoverClose();
//                           }}
//                         >
//                           <Trash2 size={16} /> Supprimer
//                         </button>
//                         <div
//                           onClick={(e) => {
//                             localStorage.removeItem('selectedUser');
//                             setSelectedUser(null);
//                             handlePopoverClose();
//                             e.stopPropagation();
//                           }}
//                           className="flex items-center gap-2 text-gray-700 cursor-pointer hover:text-gray-500"
//                         >
//                           <X size={16} /> Fermer discussion
//                         </div>
//                       </div>
//                     </Popover>
//                   }
//                 >
//                   <div
//                     className="p-2 hover:bg-gray-200 hidden rounded-full cursor-pointer transition-colors"
//                     onClick={(e) => {
//                       handlePopoverOpen(user.id);
//                       e.stopPropagation();
//                     }}
//                   >
//                     <MoreVertical className="w-4 h-4 text-gray-600" />
//                   </div>
//                 </Whisper>
//               </div>


//             </div>
//           )) : (
//             <div className='w-full pt-10'>
//               <div className="text-gray-500 flex flex-col items-center p-4">
//                 <UserRoundSearch size={100} />
//               </div>
//               <div className="text-gray-500  text-center p-2  cursor-pointer border-2">
//                 Aucun utilisateur trouvé avec <span className='font-bold'>{searchTerm}</span>
//               </div>
//             </div>
//           )
//         }
//       </div>
//     )


//   };
//   const render_utilisateur_online = () => {

//     if (usersList_online_getted.length === 0) {
//       return (
//         <div className='w-full pt-10'>
//           <div className="text-gray-500 flex flex-col items-center p-4">
//             <div> Aucun utilisateur en ligne.</div>
//             <HeadphoneOff size={100} />
//           </div>
//           <div onClick={() => { setSelectedUser_Online(false); setSelectedUser_Chat(true); setSelectedUser_Group(false); setSelectedUser_Non_lue(false) }} className="text-blue-500 text-center p-2  cursor-pointer border-2">
//             Aller au discussion
//           </div>
//         </div>
//       );
//     }



//     return usersList_online_getted.map((user) => (
//       <div
//         key={user.id}
//         onClick={() => { handleUserClick(user.id); setIsSlideMobile(false) }}
//         className={`flex items-center w-full hover:scale-105  duration-500   cursor-pointer  transition-all ${selectedUser === user.id ? 'border-x-2  border-[#25cc25]  bg-[#25cc2533] rounded-none' : 'border-gray-50 border-y-[0.1px] hover:bg-gray-100 bg-white'} p-2 rounded relative`}
//         initial={{ opacity: 0, y: -20 }}
//         animate={{ opacity: 1, y: 0 }}
//         exit={{ opacity: 0, y: 20 }}
//         transition={{
//           duration: 0.5,
//           delay: 0.2,
//           ease: [0, 0.71, 0.2, 1.01],
//         }}
//       >


//         <div className="flex items-center justify-start h-full w-full">
//           <div className='w-10 h-10 relative'>
//             {user.photo !== null ? (
//               <img
//                 src={`https://rtc.eac-advisor.org/imageUsers/${user.photo}`}
//                 className=" w-full h-full object-cover rounded-full mr-4"
//                 alt='     '
//               />
//             ) : (
//               <div className='bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full'>
//                 {user.name && user.name.charAt(0)}
//               </div>
//             )}
//             {user.etat === "En ligne" && (
//               <span className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-white" />
//             )}
//           </div>
//           <div className="w-full relative overflow-hidden h-12 ml-1">
//             <div className="w-[70%] text-nowrap overflow-hidden text-xs text-ellipsis text-gray-500">
//               {user.id === parseInt(userId_login, 10) ? <>Vous</> : <>~{user.name}~</>}
//             </div>
//             <div className="w-full absolute top-0 items-end flex flex-col pr-2">
//               {user.lastMessageTime && (
//                 <div className="text-[10px] bg-[gray] rounded text-white p-1 first-letter:uppercase">
//                   {timeSince(user.lastMessageTime)}
//                 </div>
//               )}
//               {user.unreadMessagesSent > 0 && (
//                 <div className="bg-[#25cc25] w-5 h-5 right-1 flex justify-center items-center text-white text-[10px] rounded-full p-1">
//                   {user.unreadMessagesSent}
//                 </div>
//               )}
//             </div>
//             <div className="text-gray-500 flex flex-col justify-center">
//               {(newMessages[user.id] && selectedUser !== user.id) ? (
//                 <div className="flex">
//                   <div className="text-[red]">Brouillon:</div>
//                   <div className="text-nowrap overflow-hidden ml-1 text-ellipsis w-[60%]">
//                     {newMessages[user.id]}
//                   </div>
//                 </div>
//               ) : (
//                 <div className='flex items-center'>
//                   <div className={`${selectedUser === user.id ? 'text-gray-500' : 'text-gray-400'} text-[11px] text-ellipsis w-[80%] overflow-hidden text-nowrap`}>
//                     {user.lastMessage}

//                   </div>
//                   {user.lastMessage !== "j'utilise le chat brt" && user.isSendId ? (
//                     <div className="ml-2 relative top-1">
//                       {user.unreadMessagesReceived === 0 ? (
//                         <CheckCheck size={13} color="#25cc25" />
//                       ) : (
//                         <Clock8 size={13} color="gray" />
//                       )}
//                     </div>
//                   ) : null}
//                 </div>
//               )}
//               <div className="">
//                 {user.etat === "En ligne" ? (
//                   <div className="flex items-center">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       className="h-2 w-2 text-green-500"
//                       fill="currentColor"
//                       viewBox="0 0 20 20"
//                     >
//                       <circle cx="10" cy="10" r="5" />
//                     </svg>
//                     <span className="ml-1 text-green-500 text-[9px]">En ligne</span>
//                   </div>
//                 ) : (
//                   <div className="flex items-center">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       className="h-2 w-2 text-gray-400"
//                       fill="currentColor"
//                       viewBox="0 0 20 20"
//                     >
//                       <circle cx="10" cy="10" r="5" />
//                     </svg>
//                     <span className="ml-1 text-gray-400 text-[9px]">Hors ligne</span>
//                   </div>
//                 )}
//               </div>

//             </div>
//           </div>
//         </div>


//       </div>
//     ));
//   };
//   const timeSince = (date) => {
//     const seconds = Math.floor((new Date() - new Date(date)) / 1000);

//     let interval = Math.floor(seconds / 31536000);
//     if (interval > 1) return `il y a ${interval} ans`;

//     interval = Math.floor(seconds / 2592000);
//     if (interval > 1) return `il y a ${interval} mois`;

//     interval = Math.floor(seconds / 86400);
//     if (interval > 1) return `il y a ${interval} j`;

//     interval = Math.floor(seconds / 3600);
//     if (interval > 0) {
//       const minutes = Math.floor((seconds % 3600) / 60);
//       return `il y a ${interval}h${minutes > 0 ? ':' + minutes : ''}`;
//     }

//     interval = Math.floor(seconds / 60);
//     if (interval > 1) return `il y a ${interval} min`;

//     return seconds > 1 ? `il y a ${seconds} s` : `à l'instant`;
//   };
//   const [allUsers_Online, SetallUsers_Online] = useState(0)
//   useEffect(() => {
//     SetallUsers_Online(usersList_online_getted.length)
//   }, [usersList_online_getted.length])
//   const render_header_Slide = () => {
//     return (
//       <>
//         <div className="bg-white border-b flex justify-between items-center h-[7.5vh] w-full border-gray-200 p-1 sm:px-4 sm:py-3 shadow-sm">
//           <div className='text-lg'>
//             <span className='font-serif'>BTR</span> Chat</div>
//           <div>

//             <Whisper
//               trigger="hover"
//               placement='bottom'
//               speaker={
//                 <Popover className='text-nowrap'>
//                   {parseInt(usersAllList.length).toLocaleString("FR-fr")} utilisateurs
//                 </Popover>
//               }
//             >
//               <button className="p-2 relative hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
//                 <SquareUserRound color={selectedUser_Online ? '#25cc25' : 'gray'} />
//                 {usersAllList.length > 0
//                   &&
//                   <div className='absolute w-5 h-5 left-5 text-[10px] flex justify-center items-center rounded text-white bg-[#25cc25]'>{formattedCount}</div>
//                 }
//               </button>
//             </Whisper>

//             <Whisper
//               trigger="click"
//               placement='bottomEnd'
//               open={opensAjouter}
//               speaker={
//                 <Popover>
//                   <div>
//                     <div className='flex items-center gap-1 p-2 hover:bg-gray-100  transition-colors'>
//                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
//                         <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
//                       </svg>
//                       <button onClick={() => setcreate_groupe(true)} className="text-nowrap" aria-label="Video Call">
//                         Nouvelle groupe
//                       </button>
//                     </div>
//                   </div>

//                 </Popover>
//               }
//             >
//               <button onClick={(e) => { e.stopPropagation(); setOpensAjouter(true) }} className="p-2 hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
//                 <Plus className="w-5 h-5 text-gray-600" />
//               </button>
//             </Whisper>


//             <button onClick={() => setInfoUsers(true)} className="p-2 hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
//               <Settings className="w-5 h-5 text-gray-600" />
//             </button>
//           </div>
//         </div>
//         <div className="bg-white border-b flex justify-between items-center h-[7.5vh] w-full border-gray-200 p-1 shadow-sm">
//           <button onClick={() => { localStorage.removeItem("selectedGroup_Id"); setSelectedGroup_Id(null); setSelected_Groupe(false); setSelectedUser_Online(false); setSelectedUser_Chat(true); setSelectedUser_Group(false); setSelectedUser_Non_lue(false) }} className={`p-2 hover:bg-gray-100 relative ${selectedUser_Chat ? 'text-[#25cc25]' : null} font-extrabold rounded-full transition-colors`} aria-label="Video Call">
//             {unreadUsersCount > 0
//               &&
//               <div className='absolute w-5 h-5 left-7 text-[10px] flex justify-center items-center rounded-xl text-white bg-[#25cc25]'>{unreadUsersCount}</div>
//             }
//             Chat
//           </button>
//           <button onClick={() => { localStorage.removeItem('selectedUser'); setSelectedUser(null); setSelected_Groupe(true); setSelectedUser_Online(false); setSelectedUser_Chat(false); setSelectedUser_Group(true); setSelectedUser_Non_lue(false) }} className={`p-2 hover:bg-gray-100 ${selectedUser_Group ? 'text-[#25cc25]' : null} font-extrabold rounded-full relative transition-colors`} aria-label="Video Call">
//             {unreadUsersCount_groupe > 0
//               &&
//               <div className='absolute w-5 h-5 left-10 text-[10px] hidden justify-center items-center rounded-xl text-white bg-[#25cc25]'>{unreadUsersCount_groupe}</div>
//             }

//             Groupe
//           </button>
//           <button onClick={() => { localStorage.removeItem('selectedUser'); localStorage.removeItem("selectedGroup_Id"); setSelectedGroup_Id(null); setSelectedUser(null); setSelected_Groupe(false); setSelectedUser_Online(false); setSelectedUser_Chat(false); setSelectedUser_Group(false); setSelectedUser_Non_lue(true) }} className={`p-2 hover:bg-gray-100 ${selectedUser_Non_lue ? 'text-[#25cc25]' : null} font-extrabold  rounded-full transition-colors`} aria-label="Video Call">
//             Non lues
//           </button>
//           <Whisper
//             trigger="hover"
//             placement='bottom'
//             speaker={
//               <Popover className='text-nowrap'>
//                 Amie en ligne
//               </Popover>
//             }
//           >
//             <button onClick={() => { localStorage.removeItem('selectedUser'); setSelected_Groupe(false); setSelectedUser_Online(true); setSelectedUser_Chat(false); setSelectedUser_Group(false); setSelectedUser_Non_lue(false) }} className="p-2 relative hover:bg-gray-100 rounded-full transition-colors" aria-label="Video Call">
//               <UsersRound color={selectedUser_Online ? '#25cc25' : 'gray'} />
//               {allUsers_Online > 0
//                 &&
//                 <div className='absolute w-5 h-5 left-5 text-[10px] flex justify-center items-center rounded text-white bg-[#25cc25]'>{allUsers_Online}</div>
//               }
//             </button>
//           </Whisper>

//         </div>
//       </>
//     );
//   }
//   const render_header_Top = () => {
//     const allUsers = [...usersList];
//     const selectedUserDetails = allUsers.find(user => user.id === selectedUser);

//     if (!selectedUserDetails) return null;

//     const { photo, name, email, etat, id } = selectedUserDetails;
//     const isCurrentUser = id === parseInt(userId_login, 10);
//     const isUserTyping = typingUsers.includes(id);
//     return (
//       <div className="flex items-center w-full pt-1 justify-between h-[10vh] bg-gray-100 rounded shadow">
//         <div className="flex items-center justify-between w-full">
//           <div className="flex items-center  w-full">
//             <div className="w-10 h-10 rounded-full  mr-4">
//               {photo ? (
//                 <img
//                   src={`https://rtc.eac-advisor.org/imageUsers/${photo}`}
//                   className="w-full h-full object-cover rounded-full"
//                   alt={name}
//                 />
//               ) : (
//                 <div className="bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full">
//                   {name.charAt(0).toUpperCase()}
//                 </div>
//               )}
//             </div>
//             <div>
//               <div className="font-bold first-letter:uppercase">
//                 <span className="font-normal">~ {name} ~</span>
//                 {isCurrentUser && <span> (Vous)</span>}
//               </div>
//               <div className="text-[10px]">{email}</div>
//               {isUserTyping ? (
//                 <div className="text-[10px] text-[blue]">En train de taper...</div>
//               ) : etat === "En ligne" ? (
//                 <div className="text-[10px] text-[#25cc25]">{etat}</div>
//               ) : (
//                 <div className="text-[10px] text-gray-500">{etat}</div>
//               )}

//             </div>
//           </div>

//           <div className={` justify-end ${mobile ? 'flex' : 'hidden'}`}>
//             <div onClick={() => setIsSlideMobile(true)} className='bg-gray-500 hover:bg-[#25cc25] p-1 rounded text-white cursor-pointer'>
//               <ArrowRight size={15} />
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };
//   useEffect(() => {
//     const hundleClick = (e) => {
//       setOpensAjouter(false)
//     }
//     window.addEventListener('click', hundleClick)
//     return () => window.removeEventListener('click', hundleClick)
//   }, [])


//   const formatCount = (num) => {
//     if (num >= 1_000_000_000_000) {
//       return (num / 1_000_000_000_000).toFixed(1).replace(/\.0$/, '') + 'T'; // Trillions
//     } else if (num >= 1_000_000_000) {
//       return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'G'; // Billions
//     } else if (num >= 1_000_000) {
//       return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'; // Millions
//     } else if (num >= 1_000) {
//       return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K'; // Thousands
//     } else {
//       return num.toString(); // Less than 1000
//     }
//   };
//   const usersCount = usersAllList.length;
//   const formattedCount = formatCount(usersCount);

//   // ............................Envoyer un message..........................

//   const [messages, setMessages] = useState([]);
//   const [messages_non_lue, setMessages_non_lue] = useState([]);
//   const [newMessages, setNewMessages] = useState({});
//   const [delayedMessages_users, setDelayedMessages_users] = useState({});


//   const fetchMessages = async (selectedUserId) => {
//     if (!selectedUserId) {
//       console.error("Aucun utilisateur sélectionné pour récupérer les messages.");
//       return;
//     }

//     try {
//       if (!Tokeni) {
//         console.error("Le jeton d'authentification est manquant. Veuillez vous connecter.");
//         return;
//       }

//       await feathersClient.authenticate({
//         strategy: 'jwt',
//         accessToken: Tokeni,
//       });

//       const response = await messageService.find({
//         query: {
//           $or: [
//             { sender_Id: parseInt(userId_login, 10), receiver_Id: parseInt(selectedUserId, 10) },
//             { sender_Id: parseInt(selectedUserId, 10), receiver_Id: parseInt(userId_login, 10) },
//           ],
//           $sort: { createdAt: 1 },
//         },
//       });

//       if (Array.isArray(response)) {
//         setMessages(prev => ({
//           ...prev,
//           [selectedUserId]: response,
//         }));
//       } else {
//         console.error("La réponse n'est pas valide :", response);
//       }
//     } catch (error) {
//       console.error("Erreur lors de la récupération des messages :", error);
//     }
//   };

//   const handleSendMessage = async (userId) => {
//     const messageContent = newMessages[userId]?.trim();
//     if (!messageContent) {
//       toast.warning("Le message ne peut pas être vide.");
//       return false;
//     }

//     // Identifiant unique pour le message temporaire
//     const tempId = Date.now();

//     // Ajouter le message temporaire à l'état local
//     setMessages((prevMessages) => ({
//       ...prevMessages,
//       [userId]: [...(prevMessages[userId] || []), {
//         id: tempId,
//         content: messageContent,
//         sender_Id: parseInt(userId_login, 10),
//         receiver_Id: parseInt(userId, 10),
//         isTemporary: true,
//         createdAt: new Date(),
//       }],
//     }));

//     // Réinitialiser immédiatement le champ de saisie
//     setNewMessages((prevNewMessages) => ({
//       ...prevNewMessages,
//       [userId]: '',
//     }));
//     localStorage.setItem('newMessages', JSON.stringify({ ...newMessages, [userId]: '' }));

//     try {
//       const serverMessage = await messageService.create({
//         content: messageContent,
//         receiver_Id: parseInt(userId, 10),
//       });

//       // Remplacer le message temporaire par la réponse du serveur
//       setMessages((prevMessages) => ({
//         ...prevMessages,
//         [userId]: (prevMessages[userId] || []).map((msg) =>
//           msg.id === tempId ? serverMessage : msg
//         ),
//       }));
//     } catch (error) {
//       console.error("Erreur lors de l'envoi du message :", error);

//       // Supprimer le message temporaire en cas d'erreur
//       setMessages((prevMessages) => ({
//         ...prevMessages,
//         [userId]: (prevMessages[userId] || []).filter(
//           (msg) => msg.id !== tempId
//         ),
//       }));

//       // Ajouter une notification pour informer l'utilisateur
//       toast.error("Erreur lors de l'envoi du message.");
//     }
//   };

//   useEffect(() => {
//     const handleMessageCreated = async (message) => {

//       if (message.receiver_Id) {
//         setMessages(prevMessages => {
//           const existingMessages = prevMessages[message.receiver_Id] || [];

//           // Vérifiez si le message existe déjà
//           if (existingMessages.some(m => m.id === message.id)) {
//             return prevMessages; // Ne rien changer si le message existe déjà
//           }

//           // Mettre à jour les messages


//           // Mettre à jour les messages
//           const updatedMessages = {
//             ...prevMessages,
//             [message.receiver_Id]: [...existingMessages, message],
//             [message.sender_Id]: [
//               ...(prevMessages[message.sender_Id] || []),
//               message,
//             ],
//           };

//           // Mettez à jour le statut de lecture pour le dernier message
//           if (message.receiver_Id === parseInt(userId_login, 10)) {
//             updatedMessages[message.sender_Id] = updatedMessages[message.sender_Id].map(msg => {
//               if (msg.id === message.id) {
//                 return { ...msg, isRead: "oui" };
//               }
//               return msg;
//             });
//           }

//           return updatedMessages;
//         });

//         // Mettre à jour le compteur de messages non lus uniquement si l'utilisateur n'est pas sélectionné
//         if (selectedUser !== message.receiver_Id && selectedUser !== message.sender_Id) {
//           setUsersList(prevUsers =>
//             prevUsers.map(user =>
//               user.id === message.sender_Id
//                 ? { ...user, unreadMessagesSent: (user.unreadMessagesSent || 0) + 1 }
//                 : user
//             )
//           );
//         }
//         if (selectedUser === message.sender_Id && parseInt(userId_login, 10) === message.receiver_Id) {
//           try {
//             await axios.patch(`https://rtc.eac-advisor.org/users/mess_All/${message.sender_Id}/read`);
//             setMessages((prevMessages) => {
//               const updatedMessages = { ...prevMessages };
//               updatedMessages[message.receiver_Id] = updatedMessages[message.receiver_Id]?.map((msg) => {
//                 if (msg.isRead === "non") {
//                   return { ...msg, isRead: "oui" };
//                 }
//                 return msg;
//               });
//               return updatedMessages;
//             });
//             fetchUsersWithMessages();
//             fetchUsersWithMessages_nonLue()
//             fethersGrouper_Users()
//           } catch (error) {
//             console.error("Erreur lors de la mise à jour des messages comme lus :", error);
//           }
//         }

//         const seleGroup = parseInt(selectedGroup_Ids, 10)
//         fetchGroupMessages(seleGroup)

//         fetchUsersWithMessages();
//         fethersGrouper_Users()

//         fetchUsersWithMessages_nonLue()
//       } else {
//         const seleGroup = parseInt(selectedGroup_Ids, 10)

//         fetchGroupMessages(seleGroup)
//         fetchUsersWithMessages();
//         fethersGrouper_Users()
//         fetchUsersWithMessages_nonLue()
//       }

//     };

//     messageService.on('created', handleMessageCreated);
//     return () => {
//       messageService.off('created', handleMessageCreated);
//     };
//   }, [selectedUser, selectedGroup_Ids]);

//   useEffect(() => {
//     const handleMessageRead = (data) => {
//       if (parseInt(data.receiver_Id, 10) === parseInt(userId_login, 10)) {
//         setMessages(prevMessages => {
//           const updatedMessages = { ...prevMessages };
//           const messageKeys = Object.keys(updatedMessages);

//           messageKeys.forEach(key => {
//             updatedMessages[key] = updatedMessages[key].map(message => {
//               if (message.isRead === "non") {
//                 return { ...message, isRead: "oui" };
//               }
//               return message;
//             });
//           });

//           return updatedMessages;
//         });
//       }
//       const seleGroup = parseInt(selectedGroup_Ids, 10)
//       fetchGroupMessages(seleGroup)
//       fetchUsersWithMessages()
//       fetchUsersWithMessages_nonLue()
//       fethersGrouper_Users()
//     };


//     usersService.on('messages_isRead', handleMessageRead);
//     return () => {
//       usersService.off('messages_isRead', handleMessageRead)
//     };
//   }, [selectedUser, selectedGroup_Ids]);

//   useEffect(() => {
//     const timeoutIds = [];
//     Object.keys(messages).forEach((usersSelected) => {
//       timeoutIds.push(
//         setTimeout(() => {
//           setDelayedMessages_users((prev) => ({
//             ...prev,
//             [usersSelected]: messages[usersSelected],
//           }));
//         }, 100)
//       );
//     });

//     return () => timeoutIds.forEach((id) => clearTimeout(id));
//   }, [messages]);
//   const renderMessages = () => {
//     const messages = delayedMessages_users[selectedUser] || [];

//     if (!messages || messages.length === 0) {
//       return (
//         <div className="text-gray-500 h-full flex flex-col justify-center items-center">
//           <div>Aucun message trouvé pour</div>
//           <div className='font-bold ml-2'>{parseInt(userId_login) === selectedUser ? 'Vous' : usersList.find(user => user.id === selectedUser)?.name}</div>
//         </div>
//       );
//     }

//     const groupedMessages = groupMessagesByDate(messages);

//     // Créer un Set pour suivre les ids des messages déjà affichés
//     const displayedMessageIds = new Set();



//     return Object.keys(groupedMessages).map(dateKey => (
//       <div key={dateKey} className="mb-4">
//         <div className="font-semibold w-full my-3 flex justify-center items-center">
//           <div className="text-center text-gray-500 text-sm mb-4">{dateKey}</div>
//         </div>

//         {groupedMessages[dateKey].map((sms, i, arr) => {
//           if (displayedMessageIds.has(sms.id)) {
//             return null;
//           }
//           displayedMessageIds.add(sms.id);
//           const previousMessage = arr[i - 1];
//           const isSameSender = previousMessage && previousMessage.sender_Id === sms.sender_Id;
//           return (
//             <div key={sms.id || i} className={`flex w-full ${sms.sender_Id === parseInt(userId_login, 10) ? 'justify-end' : 'justify-start'}`}>

//               <div className={`p-2 mb-2 w-max relative max-w-[80%] rounded ${sms.sender_Id === parseInt(userId_login, 10) ? 'bg-[#25cc25] text-white text-end' : 'bg-gray-200 text-start text-gray-800'}`}>
//                 {!isSameSender && parseInt(sms.sender_Id, 10) !== parseInt(userId_login, 10) &&
//                   <div className={`w-full h-2 rounded-bl-full  bg-gray-50 left-0 absolute`}></div>
//                 }
//                 {!isSameSender && parseInt(sms.receiver_Id, 10) !== parseInt(userId_login, 10) &&
//                   <div className={`w-full h-2 rounded-ee-full bg-gray-50 right-0 absolute`}></div>
//                 }
//                 {!isSameSender && parseInt(sms.receiver_Id) === parseInt(userId_login, 10) && parseInt(sms.sender_Id) === parseInt(userId_login, 10) && (
//                   <small className='text-red-600 text-[10px]'>Vous</small>
//                 )}

//                 <div className='whitespace-break-spaces font-sans'>{sms.content}</div>
//                 <div className={`flex mt-1 w-full items-center  text-[10px] ${parseInt(sms.sender_Id) === parseInt(userId_login) ? 'text-white justify-end' : 'text-gray-400 justify-start'}`}>


//                   <div>{format(new Date(sms.createdAt), 'HH:mm:ss')}</div>
//                   {sms.sender_Id === parseInt(userId_login, 10) && (
//                     <span className="text-xs text-gray-400 ml-2">
//                       {sms.isRead === "oui" ?
//                         <CheckCheck size={15} color='white' /> :
//                         sms.isTemporary ?
//                           <Clock8 size={15} color='gray' /> :
//                           <CheckCheck size={15} color='gray' />}
//                     </span>
//                   )}
//                 </div>
//               </div>
//             </div>
//           );
//         })
//         }
//       </div >
//     ));
//   };
//   const handleInputChange = (userId, value) => {
//     const updatedMessages = {
//       ...newMessages,
//       [userId]: value,
//     };
//     setNewMessages(updatedMessages);
//     localStorage.setItem('newMessages', JSON.stringify(updatedMessages));
//     if (!isTyping) {
//       setIsTyping(true);
//       socket.emit('typing', { isTyping: true, targetUserId: selectedUser });
//     }

//     clearTimeout(typingTimer);
//     typingTimer = setTimeout(() => {
//       setIsTyping(false);
//       socket.emit('typing', { isTyping: false, targetUserId: selectedUser });
//     }, typingTimeout);

//   };
//   const groupMessagesByDate = (messagesArray) => {
//     const groupedMessages = {};

//     messagesArray.forEach(message => {
//       const date = new Date(message.createdAt);
//       if (isNaN(date.getTime())) {
//         console.error("Date invalide pour le message:", message);
//         return;
//       }

//       let dateKey;
//       if (isToday(date)) {
//         dateKey = "Aujourd'hui";
//       } else if (isYesterday(date)) {
//         dateKey = "Hier";
//       } else if (isSameDay(subDays(new Date(), 2), date)) {
//         dateKey = "Avant-hier";
//       } else {
//         dateKey = format(date, 'dd MMMM yyyy'); // Format de date personnalisé
//       }

//       if (!groupedMessages[dateKey]) {
//         groupedMessages[dateKey] = [];
//       }

//       groupedMessages[dateKey].push(message);
//     });

//     return groupedMessages;
//   };
//   const markAllMessagesAsRead = async (userId) => {
//     const currentUserId = parseInt(userId_login, 10);
//     try {
//       if (userId === currentUserId) {
//         console.log(`Aucun besoin de mise à jour : ${userId} correspond à l'utilisateur connecté avec id ${currentUserId}.`);
//         return;
//       }
//       const response = await axios.patch(`https://rtc.eac-advisor.org/users/mess_All/${userId}/read`);
//       console.log(`${response.data.message} pour l'utilisateur ${userId}`);
//       setMessages(prevMessages => {
//         const updatedMessages = { ...prevMessages };
//         const messageKeys = Object.keys(updatedMessages);
//         messageKeys.forEach(key => {
//           updatedMessages[key] = updatedMessages[key].map(message => {
//             if (message.isRead === "non") {
//               return { ...message, isRead: "oui" };
//             }
//             return message;
//           });
//         });
//         return updatedMessages;
//       });

//       fetchUsersWithMessages();
//       fethersGrouper_Users()
//       fetchUsersWithMessages_nonLue()
//     } catch (error) {
//       console.error('Erreur lors de la mise à jour des messages :', error);
//     }
//   };
//   const fetchUsersWithMessages = async () => {
//     const params = {
//       query: {
//         query: searchTerm
//       }
//     };
    

//     try {
//       const userResponse = await usersService.find(params);
//       const users = userResponse.data || userResponse;

//       // Initialisez un compteur d'utilisateurs avec messages non lus
//       let unreadUsersCounts = 0;

//       const usersWithMessages = await Promise.all(users.map(async user => {
//         const lastMessageResponse = await messageService.find({
//           query: {
//             $or: [
//               { sender_Id: parseInt(userId_login, 10), receiver_Id: user.id },
//               { sender_Id: user.id, receiver_Id: parseInt(userId_login, 10) },
//             ],
//             $sort: { createdAt: -1 },
//             $limit: 1,
//           },
//         });

//         const lastMessage = Array.isArray(lastMessageResponse) && lastMessageResponse.length > 0 ? lastMessageResponse[0] : null;

//         const isCurrentUser = user.id === parseInt(userId_login, 10);
//         const sentUnreadMessages = user.sentUnreadMessagesByReceiver.find(msg => msg.receiver_Id === parseInt(userId_login, 10));
//         const receivedUnreadMessages = user.receivedUnreadMessagesBySender.find(msg => msg.sender_Id === parseInt(userId_login, 10));

//         const sentUnreadCount = sentUnreadMessages ? sentUnreadMessages.count : 0;
//         const receivedUnreadCount = receivedUnreadMessages ? receivedUnreadMessages.count : 0;

//         const unreadMessages_all = isCurrentUser ? 0 : sentUnreadCount;


//         if (unreadMessages_all > 0) {
//           unreadUsersCounts += 1;
//         }

//         const is_Sender_id = (lastMessage && lastMessage.sender_Id === parseInt(userId_login, 10));

//         const lastSeenDate = user.lastSeen ? new Date(user.lastSeen) : null;

//         let formattedLastSeen;
//         if (lastSeenDate) {
//           if (isToday(lastSeenDate)) {
//             formattedLastSeen = `Aujourd'hui à ${format(lastSeenDate, 'HH:mm', { locale: fr })}`;
//           } else if (isYesterday(lastSeenDate)) {
//             formattedLastSeen = `Hier à ${format(lastSeenDate, 'HH:mm', { locale: fr })}`;
//           } else if (differenceInDays(new Date(), lastSeenDate) < 7) {
//             formattedLastSeen = `${format(lastSeenDate, 'EEEE à HH:mm', { locale: fr })}`;
//           } else {
//             formattedLastSeen = format(lastSeenDate, 'dd/MM/yyyy à HH:mm', { locale: fr });
//           }
//         } else {
//           formattedLastSeen = 'Hors ligne';
//         }

//         return {
//           id: user.id,
//           name: user.nom_complet,
//           photo: user.profil,
//           unreadMessages_all,
//           unreadMessagesSent: sentUnreadCount,
//           unreadMessagesReceived: receivedUnreadCount,
//           email: user.email,
//           IsActive: user.IsActive,
//           status: user.status,
//           etat: user.etat === 'online' ? 'En ligne' : formattedLastSeen,
//           isSendId: is_Sender_id,
//           lastMessage: lastMessage
//             ? (lastMessage.sender_Id === parseInt(userId_login, 10) ? `Vous: ${lastMessage.content}` : lastMessage.content)
//             : "j'utilise le chat brt",
//           lastMessageTime: lastMessage ? new Date(lastMessage.createdAt) : null,
//         };
//       }));

//       const filteredUsersOnline = usersWithMessages.filter(user => user.etat === 'En ligne' && user.id !== parseInt(userId_login, 10));

//       const sortedUsers = usersWithMessages.sort((a, b) => {
//         if (a.lastMessageTime && b.lastMessageTime) {
//           return b.lastMessageTime - a.lastMessageTime;
//         }
//         return a.lastMessageTime ? -1 : 1;
//       });

//       const sortedUsersUsersOnline = filteredUsersOnline.sort((a, b) => {
//         if (a.lastMessageTime && b.lastMessageTime) {
//           return b.lastMessageTime - a.lastMessageTime;
//         }
//         return a.lastMessageTime ? -1 : 1;
//       });

//       // Mettez à jour les états avec les données triées et le compteur des utilisateurs avec messages non lus
//       setUsersList_online_getted(sortedUsersUsersOnline);
//       setUsersList(sortedUsers);

//       // Mettez le compteur des utilisateurs ayant des messages non lus
//       setUnreadUsersCount(unreadUsersCounts);

//     } catch (error) {
//       console.error('Erreur lors de la récupération des utilisateurs :', error);
//     }
//   };
//   useEffect(() => {
//     fetchUsersWithMessages()
//   }, [searchTerm])

//   const fetchUsersWithMessages_nonLue = async () => {
//     try {
//       const reponse = await axios.get('https://rtc.eac-advisor.org/users/user_unRead', tokenUtile);
//       const usersList_online = reponse.data.users_Message_non_lue;

//       if (!Array.isArray(usersList_online)) {
//         console.warn('La liste des utilisateurs en ligne est invalide.');
//         setMessages_non_lue([]);
//         return;
//       }

//       const usersWithMessages = await Promise.all(
//         usersList_online.map(async (user) => {
//           const lastMessageResponse = await messageService.find({
//             query: {
//               $or: [
//                 { sender_Id: parseInt(userId_login, 10), receiver_Id: user.id },
//                 { sender_Id: user.id, receiver_Id: parseInt(userId_login, 10) },
//               ],
//               $sort: { createdAt: -1 },
//               $limit: 1,
//             },
//           });

//           const lastMessage = Array.isArray(lastMessageResponse.data) && lastMessageResponse.data.length > 0
//             ? lastMessageResponse.data[0]
//             : null;

//           const isCurrentUser = user.id === parseInt(userId_login, 10);
//           const sentUnreadMessages = user.sentUnreadMessagesByReceiver.find(
//             (msg) => msg.receiver_Id === parseInt(userId_login, 10)
//           );
//           const receivedUnreadMessages = user.receivedUnreadMessagesBySender.find(
//             (msg) => msg.sender_Id === parseInt(userId_login, 10)
//           );

//           const sentUnreadCount = sentUnreadMessages ? sentUnreadMessages.count : 0;
//           const receivedUnreadCount = receivedUnreadMessages ? receivedUnreadMessages.count : 0;
//           const is_Sender_id = lastMessage && lastMessage.sender_Id === parseInt(userId_login, 10);

//           return {
//             id: user.id,
//             name: user.nom_complet,
//             photo: user.profil,
//             unreadMessages_all: isCurrentUser ? 0 : sentUnreadCount + receivedUnreadCount,
//             unreadMessagesSent: sentUnreadCount,
//             unreadMessagesReceived: receivedUnreadCount,
//             email: user.email,
//             IsActive: user.IsActive,
//             status: user.status,
//             etat: user.etat === 'online' ? 'En ligne' : 'Hors ligne',
//             isSendId: is_Sender_id,
//             lastMessage: lastMessage
//               ? (lastMessage.sender_Id === parseInt(userId_login, 10)
//                 ? `Vous: ${lastMessage.content}`
//                 : lastMessage.content)
//               : "j'utilise le chat brt",
//             lastMessageTime: lastMessage ? new Date(lastMessage.createdAt) : null,
//           };
//         })
//       );

//       // Filtrer les utilisateurs avec unreadMessagesSent > 0
//       const filteredUsers = usersWithMessages.filter((user) => user.unreadMessagesSent > 0);

//       // Trier les utilisateurs en fonction de lastMessageTime
//       const sortedUsers = filteredUsers.sort((a, b) => {
//         if (a.lastMessageTime && b.lastMessageTime) {
//           return b.lastMessageTime - a.lastMessageTime; // Tri décroissant
//         }
//         return a.lastMessageTime ? -1 : 1;
//       });

//       setMessages_non_lue(sortedUsers);
//     } catch (error) {
//       console.error('Erreur lors de la récupération des utilisateurs :', error);
//     }
//   };
//   const render_utilisateur_message_non_lue = () => {
//     if (messages_non_lue.length === 0) {
//       return (
//         <div className='w-full pt-10'>
//           <div className="text-gray-500 flex flex-col items-center p-4">
//             <div> Aucun message non lu.</div>
//             <MessageCircleOff size={100} />
//           </div>
//           <div onClick={() => { setSelectedUser_Online(false); setSelectedUser_Chat(true); setSelectedUser_Group(false); setSelectedUser_Non_lue(false) }} className="text-blue-500 text-center p-2  cursor-pointer border-2">
//             Aller au discussion
//           </div>
//         </div>
//       );
//     }
//     return messages_non_lue.map((user) => (
//       <div
//         key={user.id}
//         onClick={() => { handleUserClick(user.id); setIsSlideMobile(false) }}
//         className={`flex items-center w-full hover:scale-105  duration-500   cursor-pointer  transition-all ${selectedUser === user.id ? 'border-x-2  border-[#25cc25]  bg-[#25cc2533] rounded-none' : 'border-gray-50 border-y-[0.1px] hover:bg-gray-100 bg-white'} p-2 rounded relative`}
//         initial={{ opacity: 0, y: -20 }}
//         animate={{ opacity: 1, y: 0 }}
//         exit={{ opacity: 0, y: 20 }}
//         transition={{
//           duration: 0.5,
//           delay: 0.2,
//           ease: [0, 0.71, 0.2, 1.01],
//         }}
//       >


//         <div className="flex items-center justify-start h-full w-full">
//           <div className='w-10 h-10 relative'>

//             {user.photo !== null ? (
//               <img
//                 src={`https://rtc.eac-advisor.org/imageUsers/${user.photo}`}
//                 className=" w-full h-full object-cover rounded-full mr-4"
//                 alt='     '
//               />
//             ) : (
//               <div className='bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full'>
//                 {user.name && user.name.charAt(0)}
//               </div>
//             )}
//             {user.etat === "En ligne" && (
//               <span className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-white" />
//             )}
//           </div>
//           <div className="w-full relative overflow-hidden h-12 ml-1">
//             <div className="w-[70%] text-nowrap overflow-hidden text-ellipsis">
//               {user.name}
//             </div>
//             <div className="w-full absolute top-0 items-end flex flex-col pr-2">
//               {user.lastMessageTime && (
//                 <div className="text-[10px] bg-[gray] rounded text-white p-1 first-letter:uppercase">
//                   {timeSince(user.lastMessageTime)}
//                 </div>
//               )}
//               {user.unreadMessagesSent > 0 && (
//                 <div className="bg-[#25cc25] w-5 h-5 right-1 flex justify-center items-center text-white text-[10px] rounded-full p-1">
//                   {user.unreadMessagesSent}
//                 </div>
//               )}
//             </div>
//             <div className="text-gray-500 flex flex-col justify-center">
//               {(newMessages[user.id] && selectedUser !== user.id) ? (
//                 <div className="flex">
//                   <div className="text-[red]">Brouillon:</div>
//                   <div className="text-nowrap overflow-hidden ml-1 text-ellipsis w-[60%]">
//                     {newMessages[user.id]}
//                   </div>
//                 </div>
//               ) : (
//                 <div className='flex items-center'>
//                   <div className={`${selectedUser === user.id ? 'text-gray-500' : 'text-gray-400'} text-[11px] text-ellipsis w-[80%] overflow-hidden text-nowrap`}>
//                     {user.lastMessage}

//                   </div>
//                   {user.lastMessage !== "j'utilise le chat brt" && user.isSendId ? (
//                     <div className="ml-2 relative top-1">
//                       {user.unreadMessagesReceived === 0 ? (
//                         <CheckCheck size={13} color="#25cc25" />
//                       ) : (
//                         <Clock8 size={13} color="gray" />
//                       )}
//                     </div>
//                   ) : null}
//                 </div>
//               )}
//               <div className="">
//                 {user.etat === "En ligne" ? (
//                   <div className="flex items-center">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       className="h-2 w-2 text-green-500"
//                       fill="currentColor"
//                       viewBox="0 0 20 20"
//                     >
//                       <circle cx="10" cy="10" r="5" />
//                     </svg>
//                     <span className="ml-1 text-green-500 text-[9px]">En ligne</span>
//                   </div>
//                 ) : (
//                   <div className="flex items-center">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       className="h-2 w-2 text-gray-400"
//                       fill="currentColor"
//                       viewBox="0 0 20 20"
//                     >
//                       <circle cx="10" cy="10" r="5" />
//                     </svg>
//                     <span className="ml-1 text-gray-400 text-[9px]">Hors ligne</span>
//                   </div>
//                 )}
//               </div>

//             </div>
//           </div>
//         </div>


//       </div>
//     ));
//   };



//   // ...................group...................
//   const [groupes, SetGroupes] = useState([])
//   const [newMessag_gro, setNewMessag_gro] = useState({});
//   const [groupDataMessage, setGroupDataMessage] = useState([]);
//   const [delayedMessages, setDelayedMessages] = useState({});
//   useEffect(() => {
//     const seleGroup = parseInt(selectedGroup_Ids, 10)
//     fetchGroupMessages(seleGroup)
//     fethersGrouper_Users()
//   }, [selectedGroup_Id])

//   // Gestion du clic sur un groupe
//   const handleGroupClick = (group_Id) => {
//     setSelectedGroup_Id(group_Id);
//     fetchGroupMessages(group_Id)
//     localStorage.setItem("selectedGroup_Id", JSON.stringify(group_Id));
//     fethersGrouper_Users()

//   };
//   const fethersGrouper_Users = async () => {
//     try {
//       const groups = await axios.get(
//         'https://rtc.eac-advisor.org/group/group_userId_message', {
//         ...tokenUtile,
//         params: { query: searchTerm_Group }
//       }
//       );
//       // Obtenez les groupes
//       const usersWithMessages = groups.data.users_groupe_message;
//       const sortedGroups = usersWithMessages.sort((a, b) => {
//         // Récupérer le dernier message pour chaque groupe
//         const lastMessageA = a.messages.length > 0
//           ? new Date(a.messages[a.messages.length - 1].timestamp).getTime()
//           : null;
//         const lastMessageB = b.messages.length > 0
//           ? new Date(b.messages[b.messages.length - 1].timestamp).getTime()
//           : null;

//         // Trier les groupes :
//         // 1. Si les deux ont des messages, comparez par timestamp
//         if (lastMessageA && lastMessageB) {
//           return lastMessageB - lastMessageA;
//         }

//         // 2. Si l'un des deux n'a pas de message, placez celui avec des messages en premier
//         if (lastMessageA) return -1;
//         if (lastMessageB) return 1;

//         // 3. Si aucun des deux n'a de message, gardez leur ordre initial
//         return 0;
//       });
//       // Mettez à jour l'état avec les groupes triés



//       // Comptez les groupes avec des messages non lus
//       const groupsWithUnreadMessages = usersWithMessages.filter(group => {
//         return group.messages.some(message => message.message_Read === 'non');
//       });
//       const unreadGroupCount = groupsWithUnreadMessages.length;
//       setUnreadUsersCount_groupe(unreadGroupCount)
//       SetGroupes(sortedGroups);
//     } catch (error) {
//       console.log("Il y a une erreur lors de récupération des groupes:", error);
//     }
//   };
//   useEffect(() => {
//     fethersGrouper_Users()
//   }, [searchTerm_Group])
//   const render_groupe = () => {



//     return (
//       <>
//         <div className={`p-2 flex  items-center`}>
//           <input
//             type="text"
//             className="w-full px-2 py-1.5 border outline-none focus:border-[#25cc25] rounded-md"
//             placeholder="Rechercher par nom de groupe"
//             value={searchTerm_Group}
//             onChange={(e) => setSearchTerm_Group(e.target.value)}

//           />
//         </div>
//         {groupes && groupes.length > 0 ? groupes.map((group) => {
//           // Récupérer le dernier message
//           const lastMessage = group.messages.length > 0
//             ? group.messages[group.messages.length - 1]
//             : null;

//           // Formatage des données du dernier message
//           const lastMessageSender = lastMessage ? lastMessage.sender_name : "J'utilise BRT CHAT";
//           const lastMessageContent = lastMessage ? lastMessage.content : null;
//           const lastMessageTime = lastMessage
//             ? new Date(lastMessage.timestamp).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
//             : null;

//           // Check if any users are typing in the current group
//           const typingUser = typingUsers_group.find((groupTyping) => groupTyping.groupId === group.groupId);
//           const lastTypingUser = typingUser && typingUser.users.length > 0
//             ? typingUser.users[typingUser.users.length - 1].name
//             : null;

//           return (
//             <div
//               key={group.groupId}
//               onClick={() => { handleGroupClick(group.groupId); setIsSlideMobile(false) }}
//               className={`flex items-center w-full hover:scale-105 duration-500 cursor-pointer transition-all ${selectedGroup_Id === group.groupId ? 'border-x-2 border-[#25cc25] bg-[#25cc2533] rounded-none' : 'border-gray-50 border-y-[0.1px] hover:bg-gray-100 bg-white'} p-2 rounded relative`}
//             >

//               <div className="flex items-center justify-start h-full w-full">
//                 <div className='w-10 h-10 relative'>
//                   {group.groupProfil !== null ? (
//                     <img
//                       src={`https://rtc.eac-advisor.org/imageUsers/${group.groupProfil}`}
//                       className="w-full h-full object-cover rounded-full mr-4"
//                       alt=' '
//                     />
//                   ) : (
//                     <div className='bg-green-600 w-full h-full flex items-center justify-center text-white text-[20px] font-bold rounded-full'>
//                       {group.groupName && group.groupName.charAt(0)}
//                     </div>
//                   )}
//                 </div>
//                 <div className="w-full relative overflow-hidden h-12 ml-1">
//                   <div className="w-[70%] text-nowrap overflow-hidden first-letter:uppercase font-bold text-ellipsis">
//                     {group.groupName}
//                   </div>


//                   {lastTypingUser ? (
//                     <div className="w-[70%] text-nowrap overflow-hidden text-[blue] text-[14px] text-ellipsis">
//                       <span> ~ {lastTypingUser}~ est train d'ecrire...</span>
//                     </div>
//                   ) :
//                     <div className="w-[70%] text-nowrap overflow-hidden text-[15px] text-ellipsis">
//                       <span className='text-gray-500'>~{lastMessageSender}~</span>: {lastMessageContent}
//                     </div>
//                   }


//                   <div className="w-full absolute top-0 items-end flex flex-col pr-2">
//                     {group.unreadCount > 0 &&
//                       <div className="bg-[#25cc25] w-5 h-5 right-1 flex justify-center items-center text-white text-[10px] rounded-full p-1">
//                         {group.unreadCount}
//                       </div>
//                     }
//                     {
//                       lastMessageTime &&
//                       <div className="text-[10px] bg-[gray] rounded text-white p-1 first-letter:uppercase">
//                         {lastMessageTime}
//                       </div>
//                     }
//                   </div>
//                 </div>
//               </div>
//             </div>
//           );

//         }) :
//           <div className='w-full pt-10'>
//             <div className="text-gray-500 flex flex-col items-center p-4">
//               <div> Aucun groupe disponible.</div>
//               <Group size={100} />
//             </div>
//             <div onClick={() => { setcreate_groupe(true); setSelectedUser_Online(false); setSelectedUser_Chat(false); setSelectedUser_Group(false); setSelectedUser_Non_lue(false) }} className="text-blue-500 text-center p-2 cursor-pointer border-2">
//               Créer un groupe
//             </div>
//           </div>
//         }
//       </>
//     )



//   };
//   const render_header_Top_group = () => {
//     const selectedGroupDetails = groupes.find(group => group.groupId === selectedGroup_Id);
//     if (!selectedGroupDetails) return null;
//     const { groupProfil, groupName, members } = selectedGroupDetails;
//     const onlineMembersCount = members.filter(membre =>
//       membre.etat === 'online' && membre.id !== parseInt(userId_login, 10)
//     ).length;

//     // Vérifier les utilisateurs qui sont en train d'écrire dans ce groupe
//     const groupTypingUsers = typingUsers_group.find(group => group.groupId === selectedGroup_Id);
//     const typingUsersInGroup = groupTypingUsers ? groupTypingUsers.users : [];

//     return (
//       <div className="flex items-center justify-between h-[10vh]  rounded shadow">
//         <div className="flex items-center w-full">
//           <div className="w-10 h-10 rounded-full mr-4">
//             {groupProfil !== null ? (
//               <img
//                 src={`https://rtc.eac-advisor.org/imageUsers/${groupProfil}`}
//                 className="w-full h-full object-cover rounded-full"
//                 alt={groupProfil}
//               />
//             ) : (
//               <div className="bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full">
//                 {groupName.charAt(0).toUpperCase()}
//               </div>
//             )}
//           </div>
//           <div className='w-full'>
//             <div className='flex w-full justify-between'>
//               <div className="font-bold flex gap-1 items-center first-letter:uppercase">
//                 <div className='first-letter:uppercase'>{groupName}</div>
//                 {onlineMembersCount !== 0 &&
//                   <div className="text-gray-500 text-[12px]">
//                     {onlineMembersCount} en ligne
//                   </div>
//                 }
//               </div>
//               <div className='flex items-center'>
//                 <button className={`p-2 hover:bg-gray-100  hidden font-extrabold rounded-full transition-colors`}>
//                   <PhoneForwarded size={15} />
//                 </button>
//                 <div className={`justify-end ${mobile ? 'flex' : 'hidden'} w-1/2`}>
//                   <div onClick={() => setIsSlideMobile(true)} className='bg-gray-500 hover:bg-[#25cc25] p-1 rounded text-white cursor-pointer'>
//                     <ArrowRight size={15} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="flex gap-1 text-gray-400 text-[13px] overflow-hidden">
//               {members && members.map((membre, index) => {
//                 const isTyping = typingUsersInGroup.some(user => user.userId === membre.id);
//                 return (
//                   <div key={membre.id} className='relative flex items-start'>
//                     <div className='flex flex-col items-start'>
//                       <div className='text-nowrap text-[11px] text-ellipsis overflow-hidden'>
//                         {membre.id === parseInt(userId_login, 10) ? 'Vous' : membre.nom_complet}
//                       </div>
//                       {membre.id !== parseInt(userId_login, 10) && (
//                         isTyping ? (
//                           <div className="text-[8px] text-gray-600">
//                             <span className="font-bold text-[blue]">Ecrire...</span>
//                           </div>
//                         ) : (
//                           <div className={`text-[8px] ${membre.etat === 'online' ? 'text-green-500' : 'text-gray-500'}`}>
//                             {membre.etat === 'online' ? '(En ligne)' : membre.lastSeen !== null ? new Date(membre.lastSeen).toLocaleTimeString("fr-FR") : '(Hors ligne)'}
//                           </div>
//                         )
//                       )}
//                     </div>
//                     {index < members.length - 1 && <span className="mx-0.2">,</span>}
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };
//   const handleInputChange_Group = (groupId, value) => {


//     setNewMessag_gro((prevMessages) => ({
//       ...prevMessages,
//       [groupId]: value,
//     }));
//     localStorage.setItem('newMessage_group', JSON.stringify({ ...newMessag_gro, [groupId]: value }))

//     if (!isTyping) {
//       setIsTyping(true);
//       socket.emit('typing_group', { isTyping: true, groupId: parseInt(selectedGroup_Ids, 10) });
//     }

//     clearTimeout(typingTimer);
//     typingTimer = setTimeout(() => {
//       setIsTyping(false);
//       socket.emit('typing_group', { isTyping: false, groupId: parseInt(selectedGroup_Ids, 10) });
//     }, typingTimeout);

//   };
//   const handleSendMessageGroupe = async (groupId) => {
//     const messageContent = newMessag_gro[groupId];

//     if (!messageContent || !groupId) {
//       toast.warning("Veuillez saisir un message.");
//       return false;
//     }

//     const tempId = `temp_${Date.now()}`;
//     console.log("tempId:", tempId)

//     // Ajouter le message temporaire
//     setGroupDataMessage((prevMessages) => ({
//       ...prevMessages,
//       [groupId]: [
//         ...(prevMessages[groupId] || []),
//         {
//           id: tempId,
//           content: messageContent,
//           sender_Id: parseInt(userId_login, 10),
//           isTemporary: true,
//           timestamp: new Date(),
//         },
//       ],
//     }));

//     // Réinitialiser le champ de saisie
//     setNewMessag_gro((prevNewMessages) => ({
//       ...prevNewMessages,
//       [groupId]: '',
//     }));
//     localStorage.setItem('newMessage_group', JSON.stringify({ ...newMessag_gro, [groupId]: '' }));

//     try {
//       const serverMessage = await messageService.create({
//         content: messageContent,
//         sender_Id: parseInt(userId_login, 10),
//         groupId: groupId,
//       });

//       // Remplacer le message temporaire par celui confirmé par le serveur
//       setGroupDataMessage((prevMessages) => ({
//         ...prevMessages,
//         [groupId]: (prevMessages[groupId] || []).map((msg) =>
//           msg.id === tempId ? serverMessage : msg
//         ),
//       }));
//     } catch (error) {
//       console.error("Erreur lors de l'envoi du message :", error);

//       // Supprimer le message temporaire en cas d'erreur
//       setGroupDataMessage((prevMessages) => ({
//         ...prevMessages,
//         [groupId]: (prevMessages[groupId] || []).filter(
//           (msg) => msg.id !== tempId
//         ),
//       }));

//       toast.error("Erreur lors de l'envoi du message.");
//     }
//   };
//   const fetchGroupMessages = async (groupId) => {
//     if (!groupId) {
//       console.error("Veuillez sélectionner un groupe pour voir les messages.");
//       return false;
//     }
//     try {
//       const response = await axios.get(`https://rtc.eac-advisor.org/group/group_userId_message_detail/${groupId}`, tokenUtile);
//       setGroupDataMessage((prevMessages) => ({
//         ...prevMessages,
//         [groupId]: response.data.users_groupe_message_detail.messages,
//       }));

//     } catch (err) {
//       console.log("Erreur lors de la récupération des messages du groupe.", err);
//     }
//   };

//   useEffect(() => {
//     const timeoutIds = [];
//     Object.keys(groupDataMessage).forEach((groupId) => {
//       timeoutIds.push(
//         setTimeout(() => {
//           setDelayedMessages((prev) => ({
//             ...prev,
//             [groupId]: groupDataMessage[groupId],
//           }));
//         }, 300)
//       );
//     });

//     return () => timeoutIds.forEach((id) => clearTimeout(id));
//   }, [groupDataMessage]);
//   const renderGroupMessages = (groupId) => {
//     const messages = delayedMessages[groupId] || [];

//     const getDateLabel = (dateString) => {
//       const today = new Date();
//       const date = new Date(dateString);
//       const diffTime = today.setHours(0, 0, 0, 0) - date.setHours(0, 0, 0, 0);
//       const diffDays = diffTime / (1000 * 60 * 60 * 24);

//       if (diffDays === 0) return "Aujourd'hui";
//       if (diffDays === 1) return "Hier";
//       if (diffDays === 2) return "Avant-hier";

//       return `Le ${date.toLocaleDateString("fr-FR")}`;
//     };

//     const groupedMessages = messages.reduce((acc, message) => {
//       const dateLabel = getDateLabel(message.timestamp);
//       if (!acc[dateLabel]) acc[dateLabel] = [];
//       acc[dateLabel].push(message);
//       return acc;
//     }, {});

//     return (
//       <>
//         {Object.keys(groupedMessages).map((dateLabel) => (
//           <div key={dateLabel}>
//             <div className="text-center text-gray-500 text-sm mb-2">{dateLabel}</div>
//             {groupedMessages[dateLabel].map((message, index) => {
//               const previousMessage = groupedMessages[dateLabel][index - 1];
//               const isSameSenderAsPrevious =
//                 previousMessage && previousMessage.sender_id === message.sender_id;

//               return (
//                 <div
//                   key={message.id || index}
//                   className={`flex relative ${((message.sender_id === parseInt(userId_login, 10)) || (message.isTemporary && message.id)) ? "justify-end" : "justify-start"
//                     }`}
//                 >
//                   <div
//                     className={`mb-3 p-1 relative rounded ${((message.sender_id === parseInt(userId_login, 10)) || (message.isTemporary && message.id))
//                       ? "bg-[#25cc25] max-w-[90%]"
//                       : "bg-gray-300 max-w-[90%]"
//                       }`}
//                   >
//                     {!isSameSenderAsPrevious && (
//                       <div className="flex items-center pt-2 mb-2">
//                         {((message.sender_id === parseInt(userId_login, 10)) || message.isTemporary) && (
//                           <div className="w-7 h-7">
//                             {message.sender_Profile ? (
//                               <img
//                                 src={`https://rtc.eac-advisor.org/imageUsers/${message.sender_Profile}`}
//                                 className="w-full h-full object-cover rounded-full"
//                                 alt={message.sender_name || "Utilisateur"}
//                               />
//                             ) : (
//                               <div className="bg-[#25cc25] w-full h-full flex items-center justify-center text-white text-sm font-bold rounded-full">
//                                 {message.sender_name
//                                   ? message.sender_name.charAt(0)
//                                   : "?"}
//                               </div>
//                             )}
//                           </div>
//                         )}
//                         {((message.sender_id === parseInt(userId_login, 10)) || message.isTemporary) ?
//                           <></> :
//                           <div className="ml-2">
//                             <h4 className="text-sm font-bold">
//                               {message.sender_name}
//                             </h4>
//                           </div>
//                         }
//                       </div>
//                     )}
//                     <div
//                       className={`whitespace-break-spaces ${((message.sender_id === parseInt(userId_login, 10)) || message.isTemporary)
//                         ? "text-white"
//                         : ""
//                         }`}
//                     >
//                       {typeof message.content === "string" ? message.content : "Message invalide"}
//                     </div>
//                     <div className="text-[10px] mt-2 text-gray-500 italic flex items-center justify-end gap-2">
//                       <div
//                         className={`text-xs ${((message.sender_id === parseInt(userId_login, 10)) || message.isTemporary)
//                           ? "text-white"
//                           : "text-gray-500"
//                           }`}
//                       >
//                         {new Date(message.timestamp).toLocaleTimeString("fr-FR")}
//                       </div>
//                       {((message.sender_id === parseInt(userId_login, 10)) || message.isTemporary) && (
//                         <span className="text-xs text-gray-400 ml-2">
//                           {message.message_Read === "oui" ?
//                             <CheckCheck size={15} color='white' /> :
//                             message.isTemporary ?
//                               <Clock8 size={15} color='gray' /> :
//                               <CheckCheck size={15} color='gray' />}
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         ))}
//         {renderTypingUsers(selectedGroup_Id)}
//       </>
//     )
//   }
//   const markMessagesAsRead_group = async (messageId) => {
//     const dataSend = {
//       message_id: messageId,
//       userId: parseInt(userId_login, 10),
//       groupId: parseInt(selectedGroup_Ids, 10),
//     }

//     try {
//       await axios.patch('https://rtc.eac-advisor.org/group/messageRead', dataSend);
//     } catch (error) {
//       console.error('Error marking message as read:', error.response?.data || error.message);
//     }
//   };
//   useEffect(() => {
//     if (!selectedGroup_Ids) {
//       console.warn('No selected group ID found in localStorage.');
//       return;
//     }

//     const groupMessages = groupDataMessage[selectedGroup_Ids] || [];
//     const currentUserId = parseInt(userId_login, 10);
//     const messageIds = groupMessages
//       .filter(message => message.sender_id !== currentUserId)
//       .map(message => message.id);
//     if (messageIds.length > 0) {
//       markMessagesAsRead_group(messageIds);
//     }
//   }, [groupDataMessage, selectedGroup_Ids, userId_login]);
//   const renderTypingUsers = (groupId) => {
//     const groupTypingData = typingUsers_group.find(group => group.groupId === groupId);
//     if (!groupTypingData) return null;
//     const typingUsers = groupTypingData.users;

//     if (typingUsers.length === 0) return null;

//     return (
//       <div className="text-gray-500 text-[10px] mt-2 mb-5">
//         {typingUsers.map(user => (
//           <div key={user.userId} className="text-blue-500 text-[13px] font-semibold">
//             {user.name} est en train de taper...
//           </div>
//         ))}
//       </div>
//     );
//   };
//   useEffect(() => {
//     const hundelWindowClick = () => {
//       setShowMenu(false)
//     }
//     window.addEventListener('click', hundelWindowClick)
//     return window.removeEventListener('click', hundelWindowClick)
//   }, [])
//   useEffect(() => {
//     if (messagesEndRef.current) {
//       messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//     if (messages_groupeEndRef.current) {
//       messages_groupeEndRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [messages, groupDataMessage]);
//   useEffect(() => {
//     const handleConnect = async () => {
//       await fetchUsersWithMessages();
//       await fetchUsersWithMessages_nonLue();

//       await fethersGrouper_Users();
//     };

//     socket.on('connect', handleConnect);
//     return () => {
//       socket.off('connect', handleConnect);
//     };
//   }, [socket]);
//   useEffect(() => {
//     const handleUserTypingMessages = (data) => {
//       setTypingUsers((prevTypingUsers) => [...prevTypingUsers, data.userId]);
//       setTimeout(() => {
//         setTypingUsers((prevTypingUsers) => prevTypingUsers.filter((userId) => userId !== data.userId));
//       }, 2000);
//     };

//     feathersClient.service("users").on("user_typing", handleUserTypingMessages);
//     return () => {
//       feathersClient.service("users").off("user_typing", handleUserTypingMessages);
//     };
//   }, []);
//   useEffect(() => {
//     const handleGroupTyping = (data) => {
//       const { userId, groupId, isTyping, name } = data;
//       setTypingUsers_groupe((prevTypingUsers) => {
//         const updatedTypingUsers = [...prevTypingUsers];
//         // Find the group in the array or add a new one
//         let group = updatedTypingUsers.find((group) => group.groupId === groupId);
//         if (!group) {
//           group = { groupId, users: [] };
//           updatedTypingUsers.push(group);
//         }

//         if (isTyping) {
//           // Add the user to the typing list if they are not already there
//           if (!group.users.some((user) => user.userId === userId)) {
//             group.users.push({ userId, name });
//           }
//         } else {
//           // Remove the user from the typing list
//           group.users = group.users.filter((user) => user.userId !== userId);
//         }

//         // If no one is typing in the group, remove the group from the array
//         if (group.users.length === 0) {
//           updatedTypingUsers.splice(updatedTypingUsers.indexOf(group), 1);
//         }

//         return updatedTypingUsers;
//       });

//       // Optionally, remove the user after 2 seconds
//       if (isTyping) {
//         setTimeout(() => {
//           setTypingUsers_groupe((prevTypingUsers) => {
//             const updatedTypingUsers = [...prevTypingUsers];
//             const group = updatedTypingUsers.find((group) => group.groupId === groupId);
//             if (group) {
//               group.users = group.users.filter((user) => user.userId !== userId);
//               if (group.users.length === 0) {
//                 updatedTypingUsers.splice(updatedTypingUsers.indexOf(group), 1);
//               }
//             }
//             return updatedTypingUsers;
//           });
//         }, 2000);
//       }
//     }
//     feathersClient.service("users").on("user_typing_group", handleGroupTyping);

//     return () => {
//       feathersClient.service("users").off("user_typing_group", handleGroupTyping);
//     };
//   }, []);


//   return (
//     <UserContext.Provider value={{
//       // ...................users........................
//       nodifi_en_Contact, Getnodifi_en_Contact,
//       infoUsers, setInfoUsers,
//       idUsers, GetIdUsers,
//       Tokeni,
//       userId,
//       tokenUtile,
//       isAdmin, SetIsAdmin,
//       isAdminSuper, SetIsAdminSuper,
//       isVisitor, SetIsVisitor,
//       isSincrire, SetIsSincrire,
//       isUsers, SetIsUsers,
//       render_header_Slide,
//       selectedUser_Non_lue,
//       render_utilisateur_message_non_lue,
//       selectedUser_Online,
//       render_utilisateur_online,
//       render_utilisateur,
//       selectedUser,
//       render_header_Top,
//       userId_login,
//       usersList,
//       usersAllList,

//       // ............messages.............
//       renderMessages,
//       messagesEndRef,
//       newMessages,
//       handleInputChange,
//       handleSendMessage,
//       fetchUsersWithMessages,
//       fethersGrouper_Users,
//       fetchUsersWithMessages_nonLue,


//       // ............groupe.............
//       selected_groupe,
//       render_groupe,
//       selectedGroup_Id,
//       render_header_Top_group,
//       renderGroupMessages,
//       messages_groupeEndRef,
//       newMessag_gro,
//       handleInputChange_Group,
//       groupes,
//       handleSendMessageGroupe,
//       create_groupe,
//       setcreate_groupe,
//       // .................autre................
//       mobile,
//       isSlideMobile,
//       compte,

//     }}>
//       {children}
//     </UserContext.Provider>
//   );
// };


// export const useThemes = () => {
//   return useContext(UserContext);
// };














