import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { feathersClient } from "../feathersClient/feathersClient";

const Create_Groupe = ({ usersAllList, setcreate_groupe, userIds }) => {


    const [isLoadin, SetIsLoading] = useState(false)
    const [name, setName] = useState("");
    const [members, setMembers] = useState([]);

    useEffect(() => {
        if (!members.includes(userIds)) {
            setMembers([...members, userIds]);
        }
    }, [userIds, members]);


    const toggleMember = (userId) => {
        if (members.includes(userId)) {
            setMembers(members.filter((id) => id !== userId));
        } else if (members.length < 100) {
            setMembers([...members, userId]);
        } else {
            toast.error("Vous ne pouvez pas ajouter plus de 100 membres.");
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() === "") {
            toast.warning("Le nom du groupe est obligatoire !!.");
            return;
        }
        if (members.length < 3) {
            toast.warning("Veuillez sélectionner au moins 3 membres.");
            return;
        }
        if (members.length > 100) {
            toast.warning("Veuillez sélectionner au plus 100 membres.");
            return;
        }



        if (!userIds) {
            toast.warning("Il manque le id de utilisateur createurs");
            return;
        }
        SetIsLoading(true)

        const data = { name, members, creatorId: userIds }
        try {
            await feathersClient.service("group").create(data);
            toast.success("Groupe créé avec succès !");

            setName("");
            setMembers([]);
            SetIsLoading(false)
            setcreate_groupe(false)
        } catch (err) {
            toast.error(err.message);
            SetIsLoading(false)
        }
    };

    console.log("usersAllList creer groupe:", usersAllList)


    return (
        <div className="w-full mx-auto p-4">
            <h1 className="text-xl font-bold mb-4">Créer un Groupe</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex w-full justify-between">
                    <p className="text-sm text-gray-500">
                        {members.length} membre(1-100)
                    </p>
                    <button
                        disabled={isLoadin}
                        type="submit"
                        className="w-max p-2 bg-[#25cc25] text-white rounded-lg"
                    >
                        Créer
                    </button>
                </div>
                <div>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full p-2 border outline-none focus:border-[#25cc25] rounded-md"
                        placeholder="Nom du groupe"

                    />
                </div>

                <div>

                    <label className="block font-medium text-gray-600">Sélectionnez les Membres</label>
                    <div className="space-y-2 mt-2">
                        {usersAllList.map((user) => (
                            <label htmlFor={`user_${user.id}`} key={user.id} className="flex items-center  border-t border-gray-100 cursor-pointer space-x-2">
                                <div className="flex items-center justify-start h-full w-full">
                                    <div className='w-10 h-10 relative'>
                                        {user.profil !== null ? (
                                            <img
                                                src={`https://rtc.eac-advisor.org/imageUsers/${user.profil}`}
                                                className=" w-full h-full object-cover rounded-full mr-4"
                                                alt='     '
                                            />
                                        ) : (
                                            <div className='bg-green-600 w-full h-full flex items-center justify-center text-white text-[14px] font-bold rounded-full'>
                                                {user.nom_complet && user.nom_complet.charAt(0)}
                                            </div>
                                        )}
                                        {user.etat === "online" && (
                                            <span className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-white" />
                                        )}
                                        {console.log("user.etat", user.etat)}
                                    </div>
                                    <div className="w-full ml-4 relative overflow-hidden h-12">
                                        <div>{user.nom_complet}</div>
                                        <div className="text-[10px] text-gray-400">{user.email}</div>
                                    </div>
                                </div>
                                <input
                                    type="checkbox"
                                    id={`user_${user.id}`}
                                    className="w-5 h-5"
                                    checked={members.includes(user.id)}
                                    onChange={() => toggleMember(user.id)}
                                />

                            </label>
                        ))}
                    </div>
                </div>
            </form>
        </div>
    );

};

export default Create_Groupe;
